import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { Ticket } from './../../../../../time-to-change/data-access/interface/search-ticket.interface';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RecordNotesComponent } from '../notes/record-notes.component';

@Component({
  selector: 'app-record-information',
  templateUrl: './record-information.component.html',
  styleUrls: ['./record-information.component.scss']
})
export class RecordInformationComponent implements OnInit {

  @ViewChild('recordNotes') recordNotesComponent!: RecordNotesComponent;
  activeIndex = 0;
  @Input("record") record?: any ;
  @Input("module") module?: string;
  loaded = false;
  headerDetailsTitle = 'Integration.Zoho.Lead Details' ;
  defaultDetails = [];
  moreDetails = [];
  seeMore = false;
  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    setTimeout(() => {
      this.setHeaderdetailsTitle();
      this.filterKeys();
      this.loaded = true;
    });
  }


  filterKeys() {
    Object.entries(this.record).forEach(([llave, valor]) => {
      if (
          llave === 'id' ||
          llave === 'Full_Name' ||
          llave === 'Mobile' ||
          llave === 'Email' ||
          llave === 'createdTimeAsString'  ||
          llave === 'modifiedTimeAsString' ||
          llave === 'lastActivityTimeAsString'
        ) {
        this.defaultDetails.push({
          title: llave,
          value: valor
        });
      } else {
        if (
          !llave.startsWith('$') &&
          !llave.includes("Time") &&
          !llave.includes("Date") &&
          !llave.includes("AsString") &&
          llave !== "Owner" &&
          llave !== "Layout" &&
          !llave.endsWith('_By') &&
          valor != null
        ) {
          this.moreDetails.push({
            title: this.getDisplayLabel(llave),
            value: valor
          });
        } else {
          if (llave === "Owner") {
            const objectWithName = valor as { name: string };
            this.moreDetails.push({
              title: 'Owner',
              value:  `${objectWithName?.name || ''}`
            });

          }
        }
      }
      console.log(`${llave}: ${valor}`);
    });
  }

  mapDefaultTitles(title: string): string {
    if (title === 'id') return "Record id" ;
    if (title === 'Full_Name') return "Full name" ;
    if (title === 'modifiedTimeAsString') return "Modification time" ;
    if (title === 'createdTimeAsString') return "Creation time" ;
    if (title === 'lastActivityTimeAsString') return "Time of last activity" ;
    return title;
  }

  mapMoreTitles(title: string): string {
    if (title === 'id') return "Record id" ;
    if (title === 'Full_Name') return "Full name" ;
    if (title === 'modifiedTimeAsString') return "Modification time" ;
    if (title === 'createdTimeAsString') return "Creation time" ;
    if (title === 'lastActivityTimeAsString') return "Time of last activity" ;
    return title;
  }

  setHeaderdetailsTitle(): void {
    if(this.module === 'CONTACTS') {
      this.headerDetailsTitle = 'Integration.Zoho.Contact Details' ;
    }
  }

  getDisplayLabel(apiFieldName: string): string {
    let layoutId = this.record.Layout.id ;
    let module = this.record._module.toLowerCase() ;
    let displayLabel = '';
    const metadataAsString = localStorage.getItem(module+'-metadata-crm');
    if (metadataAsString) {
      const metadataLayouts = JSON.parse(metadataAsString);
      metadataLayouts.forEach(metadataLayout => {
        if (metadataLayout.layoutId == layoutId) {
          let sections = metadataLayout.sections;
          sections.forEach(section => {
            section.fields.forEach(_field => {
              if (_field.apiName === apiFieldName) {
                displayLabel = _field.displayLabel;
              }
            });
          });
        }
      });
    }
    return displayLabel || apiFieldName.replaceAll('_', ' ') ;
  }

  emitOthersAction(emit: any): void {
    this.emitAction.emit(emit);
  }

  onAddNote() {
    if (this.recordNotesComponent) {
      this.recordNotesComponent.showForm();
    }
  }

  onRefreshNotes() {
    if (this.recordNotesComponent) {
      this.recordNotesComponent.getNotes(true);
    }
  }
}
