import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { ZohoDeskBackendService } from '../../zoho-desk/data-access/zoho-desk-backend.service';
import { Subscription } from 'rxjs';
import { ZohoCrmBackendService } from '../../zoho-crm/data-access/zoho-crm-backend.service';

@Component({
  selector: 'formly-field-lookup-autocomplete-input',
  template: `
    <div class="flex flex-column gap-1">
      <label style="color: #7A7F9A; font-size: 12px; font-weight: 600;" >{{ props['display_label'] }}</label>
      <p-dropdown
        [options]="filteredSuggestions"
        [formControl]="formControl"
        [formlyAttributes]="field"
        optionLabel="label"
        optionValue="value"
        [filter]="true"
        filterBy="label"
        [showClear]="true"
        placeholder="Search {{ props['display_label']}}"
        [loading]="loading"
      ></p-dropdown>
    </div>
  `,
})
export class LookUpAutocompleteFieldType extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);

  loading = false;
  public suggestions: any[] = [];
  public filteredSuggestions: any[] = [];
  private subscriptions = new Subscription();

  get type() {
    return this.props.type || 'text';
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  filter(event: AutoCompleteCompleteEvent) {

    if (!event.query || event.query.length == 0) {
      this.filteredSuggestions = this.suggestions;
    }
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.suggestions as any[]).length; i++) {
        let suggestion = (this.suggestions as any[])[i];
        if (suggestion.value.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(suggestion);
        }
    }

    this.filteredSuggestions = filtered;
  }

  loadData(): void {
    this.loading = true;
    const departmentId = this.props['departmentId'];
    const zohoType = this.props['zohoType'] ;
    if (zohoType === 'desk') {
      let m = 'accounts'
      if (this.props['api_name'] === 'Departments') { m = 'departments'}
      if (this.props['api_name'] === 'Assignees') { m = 'agents'}
      if (this.props['api_name'] === 'Products') { m = 'products'}
      if (this.props['api_name'] === 'Contacts') { m = 'contacts'}
      const loadLookup$ = this._ZohoDeskBackendService
          .suggestionsLookup(m, departmentId)
          .subscribe(
            (resp: HttpResponse<any[]>) => {
                if (resp.status == 200) {
                  const data = [];
                  resp.body.forEach(d => {
                    data.push({
                      value: d.id,
                      label: ((d.value && d.value.length > 30) ? (d.value.slice(0,30) + '...') : d.value)
                    });
                  });
                  this.suggestions = data || [];
                  this.filteredSuggestions = this.suggestions ;
                }
                this.subscriptions.add(loadLookup$);
                this.loading = false;
                console.error(this.loading);
            },
          (error: HttpErrorResponse) => {
            this.loading = false;
            console.error(error)
          });
    }

    if (zohoType === 'crm') {
      let m = this.props['api_name']
      const loadLookup$ = this._ZohoCrmBackendService
          .suggestionsLookup(m)
          .subscribe(
            (resp: HttpResponse<any[]>) => {
                if (resp.status == 200) {
                  const data = [];
                  resp.body.forEach(d => {
                    data.push({
                      value: d.id,
                      label: ((d.value && d.value.length > 30) ? (d.value.slice(0,30) + '...') : d.value)
                    });
                  });
                  this.suggestions = data || [];
                  this.filteredSuggestions = this.suggestions ;
                }
                this.subscriptions.add(loadLookup$);
                this.loading = false;
            },
          (error: HttpErrorResponse) => console.error(error));
    }
  }
}
