import { Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';

@Component({
	selector: 'app-init-zoho-desk',
	templateUrl: './init-zoho-desk.component.html',
	styleUrls: ['./init-zoho-desk.component.scss']
})
export class InitZohoDeskComponent {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  newTicket() {
		this.emitAction.emit({
			action: 'new-ticket',
			values: null,
      module: ''
		});
	}

  openTickets() {
		this.emitAction.emit({
			action: 'open-tickets',
			values: null,
      module: ''
		});
	}

  unassignedTickets() {
		this.emitAction.emit({
			action: 'unassigned-tickets',
			values: null,
      module: ''
		});
	}
}
