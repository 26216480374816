import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';
import { ZohoCrmBackendService } from '../../../data-access/zoho-crm-backend.service';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';

@Component({
  selector: 'app-record-notes',
  templateUrl: './record-notes.component.html',
  styleUrls: ['./record-notes.component.scss']
})
export class RecordNotesComponent implements OnInit {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
	public translate = inject(TranslateService);
  private subscriptions = new Subscription();

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  noteForm: FormGroup;
  savingNote: boolean;
  loadingNotes = false;
  notes: any[] = [];
  noteContent = '';
  show = false;

  @Input("record") record?: any ;
  @Input("module") module?: string;

  ngOnInit(): void {
    setTimeout(() => {
      this.getNotes(false);
    });
  }

  getNotes(update: boolean): void {
    this.loadingNotes = true ;
    const getNotes$ = this._ZohoCrmBackendService
    .getNotes(this.module, this.record.id, update)
    .subscribe(
        (resp: HttpResponse<any[]>) => {
            if (resp.status == 200) {
              this.notes = resp.body;
              this.loadingNotes = false ;
            }
            this.subscriptions.add(getNotes$);
        },
        (error: HttpErrorResponse) => console.error(error));
	}

  addNote(): void {
    if (this.noteContent === '') {
      this.messageService.showMessage('You must enter a comment', 'warning', false);
    } else {
      const saveNote$ = this._ZohoCrmBackendService
        .saveNote({
          noteContent: this.noteContent,
          recordId: this.record.id,
          moduleZohoCrmType: this.module.toUpperCase(),
        })
        .subscribe(() => {
            this.subscriptions.add(saveNote$);
            this.show = false;
            this.getNotes(true);
        },
        (error: HttpErrorResponse) => {
          this.savingNote = false;
          this.emitAction.emit({
            action: 'error',
            values: error,
            module: ''
          });
        });
    }
	}

  showForm(): void {
    this.show = true;
  }

  deleteNote(id: string): void {
    this.loadingNotes = true;
    const deleteNote$ = this._ZohoCrmBackendService
    .deleteNote(id)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 204) {
          this.messageService.showMessage('If you have satisfactorily eliminated the note', 'success', false);
        }
        this.getNotes(true);
        this.subscriptions.add(deleteNote$);
      }, (error: HttpErrorResponse) => {
        this.loadingNotes = false;
        this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
      });
  }

  confirm(id: string) {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.Are you sure of eliminating the note?'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.deleteNote(id);
        },
        key: 'noteDialogDeleteConfirm'
    });
  }


}
