<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-8">
      <span>{{ 'Integration.Zoho.Activities' | translate }}</span>
    </div>
    <div class="col-1">
      <i
        (click)="createActivity('tasks')"
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.New task' | translate }}"
        class="pi pi-file-check mr-1"
      ></i>
    </div>

    <div class="col-1">
      <i
        (click)="createActivity('events')"
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.New event' | translate }}"
        class="pi pi-calendar-clock mr-1"
      ></i>
    </div>

    <div class="col-1">
      <i
        (click)="createActivity('calls')"
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.New call' | translate }}"
        class="pi pi-phone mr-5"
      ></i>
    </div>

    <div class="col-1">
      <i
        (click)="refreshListActivity(true)"
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.Update' | translate }}"
        class="pi pi-refresh mr-1"
      ></i>
    </div>

  </div>
</h6>
<div #childActivitiesComponents></div>
