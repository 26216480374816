<div class="search-box chat-search-box">
  <div class="input-group rounded-3"  >
    <span class="input-group-text text-muted bg-custom-light pe-1 ps-3" [ngClass]="{ 'bg-custom-white': whiteBackground }">
      <i class="material-icons-outlined search-icon font-size-18">search</i>
    </span>
    <input
      type="text"
      class="form-control bg-custom-light"
      [ngClass]="{ 'bg-custom-white': whiteBackground }"
      placeholder="{{ placeholder | translate }}"
      [(ngModel)]="inputValue"
      (ngModelChange)="handleFieldKeyUp($event)"
    />
  </div>
</div>
