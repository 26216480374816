<div class="mt-2 p-2">
  <div class="mb-3">
    <label for="exampleFormControlTextarea1" class="form-label">
      {{ 'Integration.Zoho.New comment' | translate }}
    </label>
    <textarea
      pInputTextarea
      [(ngModel)]="comment"
      class="form-control"
      rows="2"
      [disabled]="saving || loadingComments"
    >
    </textarea>
  </div>

  <div class="mb-3">
    <p-inputSwitch
    [(ngModel)]="isPrivate"
    class="mr-2"
    [disabled]="saving || loadingComments"
    /> {{ 'Integration.Zoho.Private' | translate }}
  </div>

  <div class="flex justify-content-center flex-wrap align-items-center">
    <p-button
      label="{{ 'Integration.Zoho.add' | translate }}"
      icon="pi pi-plus"
      size="small"
      class="mr-2"
      type="buttom"
      [disabled]="saving || loadingComments"
      (click)="addComment()"
    />
  </div>
</div>

<div *ngIf="loadingComments" class="spinner-border mt-5 p-2" role="status">
  <span class="visually-hidden"></span>
</div>

<div *ngIf="!loadingComments && comments && comments.length > 0" class="list-group list-group-flush mt-2 p-2">
  <div class="icon-container" *ngFor="let comment of comments">
    <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">
      <div class="flex justify-content-between gap-5">
        <div class="flex flex-column gap-1">
            <span class="text-md">{{  comment.content }}</span>
            <span *ngIf="comment.commenter" class="text-secondary text-sm"> {{ comment.commenter.firstName }} {{ comment.commenter.lastName }}</span>
            <span class="text-secondary text-sm">{{ comment.commentedTimeFormated }}</span>
        </div>

        <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
          <i *ngIf="!comment.isPublic" class="pi pi-eye-slash mr-1"></i>
          <i *ngIf="comment.isPublic" class="pi pi-eye mr-1"></i>
          <span *ngIf="!comment.isPublic" class="text-secondary text-sm">{{ 'Integration.Zoho.Private' | translate }}</span>
          <span *ngIf="comment.isPublic" class="text-secondary text-sm"> {{ 'Integration.Zoho.Public' | translate }}</span>

        </span>
      </div>
    </p-card>
  </div>
</div>

<span *ngIf="!loadingComments && (!comments || comments.length == 0)" class="mt-2 p-2">
  {{ 'Integration.Zoho.There are no comments' | translate }}
</span>
