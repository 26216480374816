<!-- start chat conversation section -->
<div class="w-100 overflow-hidden position-relative chat-container d-flex flex-column">
	<!-- Header de la conversación -->
	<div class="container-conversation-header user-chat-topbar">
		<div class="flex flex-row align-items-center justify-content-between">
			<!-- Información del usuario y canal -->
			<div class="flex flex-row gap-2">
				<div class="flex flex-row align-items-center gap-3">
					<div class="d-block d-lg-none me-2 ms-0">
						<a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
						  (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
					  </div>
					<!-- Avatar del usuario -->
					<div class="chat-avatar">
						<div class="avatar-sm">
							<span *ngIf="useGeneratedImage" class="avatar-title rounded-circle bg-primary-subtle text-primary">
								<img [src]="avatarUrl" (error)="onImageError()" (load)="onImageLoad()" alt="User Avatar" />
							</span>
							<span *ngIf="!useGeneratedImage" class="avatar-title rounded-circle bg-primary-subtle text-primary">
								{{ fullName?.charAt(0) }}
							</span>
						</div>
					</div>
					<!-- Información del nombre y número de teléfono -->
					<div class="flex flex-column gap-2">
						<div class="container-name-phone">
							<span class="name">{{ fullName }}</span>
							<span class="phoneNumber">{{ phoneNumber }}</span>
						</div>
						<div>
							<div class="primary-badge">
								<i [class]="classChannel"></i>
								{{ nameChannel }}
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- Opciones de acciones (búsqueda, contacto, transferencia, cerrar chat) -->
			<div class="flex flex-row gap-2">
				<div class="item-action" [ngClass]="{ 'item-action-active': optionActive[0] }" (click)="activeOption(0)">
					<app-field-search
						class="item-class"
						[showField]="showSearchMessage"
						(emitValue)="MessageSearch($event)"
					></app-field-search>
				</div>
				<div *ngIf="visibleOptionContact" class="item-action" [ngClass]="{ 'item-action-active': optionActive[1] }" (click)="activeOption(1)">
					<app-mini-card-contact
						class="item-class"
						[fullName]="fullName"
						[phoneNumber]="phoneNumber"
						[showField]="showInfoContact"
						(emitValue)="handleContact()"
					></app-mini-card-contact>
				</div>
				<div
					class="item-action"
					[ngClass]="{ 'item-action-active': optionActive[2] }"
					pTooltip="{{ 'Transfer Chat' | translate }}"
					tooltipPosition="left"
					(click)="activeOption(2)"
				>
					<i class="material-icons-outlined">textsms</i>
				</div>
				<div
					class="item-action"
					[ngClass]="{ 'item-action-active': optionActive[3] }"
					pTooltip="{{ 'End chat' | translate }}"
					tooltipPosition="left"
					(click)="activeOption(3)"
				>
					<i class="material-icons-outlined" placement="bottom">power_settings_new</i>
				</div>
			</div>
		</div>
	</div>

	<!-- Contenido de la conversación -->
	<div
		#scrollBar
		infinite-scroll
		[infiniteScrollUpDistance]="scrollUpDistance"
		[infiniteScrollDistance]="scrollDistance"
		[infiniteScrollThrottle]="throttle"
		[scrollWindow]="false"
		(scrolledUp)="onScrollUp($event)"
		class="chat-conversation p-3 p-lg-4 scroll-custom"
	>
		<ul class="list-unstyled mb-0" id="users-conversation">
			<ng-container *ngFor="let groupMessage of groupedMessagesByDate; let i = index">
				<app-chat-content-message
					[messageGruop]="groupMessage"
					[isFriend]="groupMessage.from !== businnessPhoneNumber"
					[fullName]="getName(groupMessage)"
					[customerFeatureStorage]="customerFeatureStorage"
					[useGeneratedImage]="useGeneratedImage"
				></app-chat-content-message>
			</ng-container>
		</ul>
	</div>
	<!-- Sección de entrada de chat -->
	<div class="chat-input-section d-flex border-top overflow-hidden">
		<app-typing-bar
			class="w-100"
			#typingBar
			[phoneNumber]="phoneNumber"
			[from]="businnessPhoneNumber"
			[serviceInstanceData]="serviceInstanceData"
			[profile]="profile"
			[setupId]="setupId"
			(messageSend)="receiveMessageSender($event)"
		></app-typing-bar>
	</div>
</div>

<!-- Dialogo para transferencia de mensaje -->
<app-dialog-transfer
	[isVisible]="isVisibleDialogTransfer"
	[options]="options"
	[optionsAgents]="optionsAgents"
	[optionsBots]="optionsBots"
	(dialogClosed)="isVisibleDialogTransfer = $event"
	(emitValue)="handleMessageTransfer($event)"
></app-dialog-transfer>
