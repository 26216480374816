import { NgModule } from '@angular/core';
import { AuthModule, LogLevel } from 'angular-auth-oidc-client';

import { environment } from '../environments/environment';

@NgModule({
	imports: [
		AuthModule.forRoot({
			config: {
				triggerAuthorizationResultEvent: true,
				postLoginRoute: environment.SSO_CONFIGURATION.POST_LOGIN_ROUTE,
				forbiddenRoute: environment.SSO_CONFIGURATION.FORBIDDEN_ROUTE,
				unauthorizedRoute: environment.SSO_CONFIGURATION.UNAUTHORIZED_ROUTE,
				logLevel: environment.production ? LogLevel.None : LogLevel.Debug,
				historyCleanupOff: true,
				authority: environment.SSO_CONFIGURATION.AUTHORITY,
				redirectUrl: `${window.location.origin}/${environment.SSO_CONFIGURATION.REDIRECT_URI}`,
				postLogoutRedirectUri: environment.SSO_CONFIGURATION.POST_LOGOUT_REDIRECT_URI,
				clientId: environment.SSO_CONFIGURATION.CLIENT_ID,
				scope: environment.SSO_CONFIGURATION.SCOPES,
				responseType: 'code',
				silentRenew: true,
				useRefreshToken: true,
				ignoreNonceAfterRefresh: true,
				triggerRefreshWhenIdTokenExpired: false,
				customParamsCodeRequest: {
					client_secret: environment.SSO_CONFIGURATION.CLIENT_SECRET
				}
			}
		})
	],
	exports: [AuthModule]
})
export class AuthConfigModule {}
