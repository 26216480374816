import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Subscription, forkJoin } from 'rxjs';
import { ConversationEventService } from 'src/app/chat/data-access/services/conversation-event.service';
import Swal from 'sweetalert2';
import { Contact, SearchContact } from '../data-access/interface/search-contact.type';
import { AccountTTCService } from '../data-access/services/account-ttc.service';
import { AuthenticationTTCService } from '../data-access/services/authentication-ttc.service';
import { ContactTTCService } from '../data-access/services/contact-ttc.service';
import { TicketService } from '../data-access/services/ticket.service';
import { AccountFormComponent } from '../ui/account-form/account-form.component';
import { ContactFormComponent } from '../ui/contact-form/contact-form.component';
import { LoginFormComponent } from '../ui/login-form/login-form.component';
import { TicketFormComponent } from '../ui/ticket-form/ticket-form.component';
import { TicketListComponent } from '../ui/ticket-list/ticket-list.component';
import { WelcomeComponent } from '../ui/welcome/welcome.component';
import { LoadingComponent } from '../ui/loading/loading.component';
import { MessageService } from '../../zoho/utils/messages.service';

@Component({
	selector: 'app-time-to-change',
	templateUrl: './time-to-change.component.html',
	styleUrls: ['./time-to-change.component.scss']
})
export class TimeToChangeComponent implements OnInit, OnDestroy {
	@ViewChild('childTTCComponents', { read: ViewContainerRef }) container!: ViewContainerRef;
	@ViewChild('loginSwal') loginSwal: SwalComponent;
	@ViewChild('saveContactSwal') saveContactSwal: SwalComponent;
	@ViewChild('saveAccountSwal') saveAccountSwal: SwalComponent;

	@Output() closeTTC: EventEmitter<any> = new EventEmitter<any>();

	private _AccountTTCService = inject(AccountTTCService);
	private _AuthenticationTTCService = inject(AuthenticationTTCService);
	private _ContactTTCService = inject(ContactTTCService);
	private _ConversationEventService = inject(ConversationEventService);
	private _ticketService = inject(TicketService);
	private messageService = inject(MessageService);

	public numberPhone = '-1';
	public options = { autoHide: false, scrollbarMinSize: 100 };
	public isAuth = false;
	public infoContac: Contact;

	private subscriptions = new Subscription();

	ngOnInit(): void {
    this.numberPhone = '-1';
    this.checkChatSelected();
    this.isAuth = false;
		this.listenToConversationEvents();
		setTimeout(() => {
      const timeToChangeToken =  localStorage.getItem('time-to-change-token');
      if (!timeToChangeToken) {
        this.startLogin();
      } else {
        this.pingService();
      }
    });
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

  checkChatSelected(): any {
    const chat: any = localStorage.getItem('selectedChatConversation');
    if (chat) {
      const queryChatActive = document.querySelectorAll('.chat-user-list li.active');
      if (queryChatActive.length > 0) {
        this.numberPhone = JSON.parse(chat).clientPhone;
      }
    }
  }

	private listenToConversationEvents(): void {
		const chatEvent$ = this._ConversationEventService.getEvent().subscribe((data) => {
      const timeToChangeToken =  localStorage.getItem('time-to-change-token');
      if (!timeToChangeToken) {
        this.startLogin();
      } else {
        this.numberPhone = data.clientPhone;
        this.container.clear();
        this.searchContact();
      }
      this.subscriptions.add(chatEvent$);
		});
	}

	private startLogin(): void {
    this.container.clear();
    const onLoginComponent = this.container.createComponent(LoginFormComponent);
    const onLoginEvent$ = onLoginComponent.instance.emitAction.subscribe((values) => {
      this.subscriptions.add(onLoginEvent$);
      if (values.action === 'login') {
        this.authenticateUser(values.values);
      } else {
        this.closeTTC.emit();
      }
    });
	}

  private startInit(): void {
    this.container.clear();
    this.container.createComponent(WelcomeComponent);
	}

	private authenticateUser(loginValues: any): void {
    const authentication$ = this._AuthenticationTTCService.authentication(loginValues).subscribe((resp) => {
      this.subscriptions.add(authentication$);
      this.loginSwal.fire();
      localStorage.setItem('time-to-change-token', resp.token)
      this.isAuth = true;
      this.container.clear();
      this.searchContact();
      this.handleTokenExpiration(resp.expiredDate); // Control de expiración
    });
	}

	private searchContact(): void {
    this.loading();
		const searchContact$ = this._ContactTTCService.searchContact(this.numberPhone).subscribe(
			(resp) => {
				this.subscriptions.add(searchContact$);
				this.infoContac = resp.data || ({} as Contact);
				this.startContact(!!resp.data, resp.data);
			},
			(error) => {
				this.startLogin();
			}
		);
	}

	private startContact(contact_exists: boolean = false, contact: Contact): void {
    this.container.clear();
		const onContactComponent = this.container.createComponent(ContactFormComponent);
		onContactComponent.instance.contact_exists = contact_exists;
    onContactComponent.instance.contact = contact;
		this.populateContactForm(onContactComponent, contact_exists, contact);

		const onContactEvent$ = onContactComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onContactEvent$);
			this.handleContactActions(values, onContactComponent);
		});
	}

	private populateContactForm(onContactComponent: any, contact_exists: boolean, contact: Contact): void {
		if (contact_exists) {
			onContactComponent.instance.form.patchValue({
				firstname: contact.firstname,
				lastname: contact.lastname,
				mobile_number: contact.mobile_number,
				email: contact.email,
				identification_type_id: contact.identification_type_id,
				identification: contact.identification,
				account_id: contact.account?.name,
				state: contact.state,
				city: contact.city,
				country: contact.country
			});
			onContactComponent.instance.form.disable();
			onContactComponent.instance.search = true;
		}
		onContactComponent.instance.form.controls.mobile_number.setValue(this.numberPhone);
	}

	private handleContactActions(values: any, onContactComponent: any): void {
		switch (values.action) {
			case 'create contact':
				this.createContact(values.values, onContactComponent);
				break;
			case 'create ticket':
				this.container.clear();
				this.startCreateTicket();
				break;
			case 'view ticket':
				this.container.clear();
				this.startViewTicket();
				break;
			case 'create account':
				this.container.clear();
				this.startAccount();
				break;
			case 'back login':
				this.container.clear();
				this.startLogin();
				break;
		}
	}

	private createContact(contactData: any, onContactComponent: any): void {
		const createContact$ = this._ContactTTCService.createContact(contactData).subscribe((response) => {
			this.subscriptions.add(createContact$);
			const accountId = Number(onContactComponent.instance.form.controls['account_id'].value);
			this.linkContactToAccount(accountId, response.contact_id);
		});
	}

	private linkContactToAccount(accountId: number, contactId: string): void {
		const linkContact$ = this._ContactTTCService.linkContactToAccount(accountId, contactId).subscribe(() => {
			this.subscriptions.add(linkContact$);
			this.saveContactSwal.fire();
			this.searchContact();
		});
	}

	private startAccount(): void {
		const onAccountComponent = this.container.createComponent(AccountFormComponent);
		const onAccountEvent$ = onAccountComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onAccountEvent$);
			if (values.action === 'cancel') {
				this.container.clear();
				this.searchContact();
			} else {
				this.createAccount(values.values);
			}
		});
	}

	private createAccount(accountData: any): void {
		const createAccount$ = this._AccountTTCService.createAccount(accountData).subscribe(
			() => {
				this.subscriptions.add(createAccount$);
				this.saveAccountSwal.fire();
			},
			(error) => {
				this.startLogin();
			}
		);
	}

	private startCreateTicket(): void {
		forkJoin([this._ticketService.getSupportType(), this._ticketService.getTicketType()]).subscribe(
			([supportType, ticketType]) => {
				const onCreateTicketComponent = this.container.createComponent(TicketFormComponent);
				onCreateTicketComponent.instance.supportTicket = supportType;
				onCreateTicketComponent.instance.ticketType = ticketType;
				onCreateTicketComponent.instance.infoContac = this.infoContac;
				onCreateTicketComponent.instance.assignData();
				this.handleTicketEvents(onCreateTicketComponent);
			}
		);
	}

	private handleTicketEvents(onCreateTicketComponent: any): void {
		const onTicketEvent$ = onCreateTicketComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onTicketEvent$);
			if (values.action === 'create') {
				this.createTicket(values.values);
			} else {
				this.container.clear();
				this.searchContact();
			}
		});
	}

	private createTicket(ticketData: any): void {
		const createTicket$ = this._ticketService.createTicket(ticketData).subscribe((response) => {
			this.subscriptions.add(createTicket$);
			Swal.fire({
				toast: true,
				position: 'top-end',
				showConfirmButton: false,
				timer: 3000,
				timerProgressBar: true,
				title: `Se ha creado el Ticket #${response.data.ticket_id}.`,
				icon: 'success'
			});
			this.container.clear();
			this.searchContact();
		});
	}

	private startViewTicket(): void {
		forkJoin([this._ticketService.getSupportType(), this._ticketService.getTicketType()]).subscribe(
			([supportType, ticketType]) => {
				const onListTicketComponent = this.container.createComponent(TicketListComponent);
				onListTicketComponent.instance.supportTickets = supportType;
				onListTicketComponent.instance.ticketTypes = ticketType;
				onListTicketComponent.instance.infoContac = this.infoContac;
				onListTicketComponent.instance.searchTickets();
				this.handleViewTicketsEvents(onListTicketComponent);
			}
		);
	}

	private handleViewTicketsEvents(onListTicketComponent: any): void {
		const onViewTicketsEvent$ = onListTicketComponent.instance.cancelTicketList.subscribe(() => {
			this.subscriptions.add(onViewTicketsEvent$);
			this.container.clear();
			this.searchContact();
		});
	}

	private handleTokenExpiration(expiredDate: string): void {
		const expirationDate = new Date(expiredDate);
		const timeToExpire = expirationDate.getTime() - Date.now();
		if (timeToExpire <= 0) {
			this.clearAndLogin();
		} else {
			setTimeout(() => {
        this.clearAndLogin();
      }, timeToExpire - 60000);
		}
	}

	private clearAndLogin(): void {
    this.messageService.showGlobalMessage('timeToChange.Your session has expired', 'warning');
    this.isAuth = false;
		this.container.clear();
		this.startLogin();
	}

	handleCloseTTC(): void {
		this.closeTTC.emit();
	}

  private pingService(): void {
    this.loading();
		const searchContact$ = this._ContactTTCService.searchContact(this.numberPhone).subscribe(
			(resp: SearchContact) => {
        if (resp && (resp.data || this.numberPhone !== '-1')) {
          this.infoContac = resp.data || ({} as Contact);
          this.startContact(!!resp.data, resp.data);
        } else {
          this.startInit();
        }
        this.isAuth = true;
				this.subscriptions.add(searchContact$);
			},
			(error: any) => {
        this.clearAndLogin();
      }
		);
	}

  loading(): void {
		this.container.clear();
		this.container.createComponent(LoadingComponent);
	}

  private logout(): void {
    this.messageService.showGlobalMessage('timeToChange.Your session has been closed successfully', 'success');
    this.isAuth = false;
    localStorage.removeItem('time-to-change-token');
		this.container.clear();
		this.startLogin();
	}

}
