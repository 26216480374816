import { Component, inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { SearchLookupResponse } from '../../models/records-response.model';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { ZohoDeskBackendService } from '../../zoho-desk/data-access/zoho-desk-backend.service';
import { ZohoCrmBackendService } from '../../zoho-crm/data-access/zoho-crm-backend.service';

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <div class="flex flex-column gap-1">
      <label style="color: #7A7F9A; font-size: 12px; font-weight: 600;" >{{ props['display_label'] }}</label>
      <p-autoComplete
          placeholder="Search {{ props['display_label']}}"
          [suggestions]="values"
          (completeMethod)="search($event)"
          [formControl]="formControl"
          [formlyAttributes]="field"
          optionLabel="label"
          optionValue="value"
      />
    </div>
  `,
})
export class LookUpFieldType extends FieldType<FieldTypeConfig> {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);

  loading = false;
  public isSearching = true;
  public values: any[] = [];

  get type() {
    return this.props.type || 'text';
  }

  search(event: AutoCompleteCompleteEvent): void {

    if (event.query && event.query.length > 2) {

      setTimeout(() => {
        this.loading = true;
        this.isSearching = false;
        const zohoType = this.props['zohoType'] ;
        if (zohoType === 'crm') {
          const searchLookUpByTerm$ = this._ZohoCrmBackendService
            .searchLookUpByTerm(this.props['api_name'],  event.query)
            .subscribe(
              (resp: HttpResponse<SearchLookupResponse[]>) => {
                  if (resp.status == 200) {
                    const data = [];
                    resp.body.forEach(d => {
                      data.push({
                        value: d.id,
                        label: d.name
                      });
                    });
                    this.values = data || [];
                  }
                  this.loading = false;
              },
            (error: HttpErrorResponse) => console.error(error));
        }

        if (zohoType === 'desk') {
          let m = 'accounts'
          if (this.props['api_name'] === 'Departments') { m = 'departaments'}
          if (this.props['api_name'] === 'Assignees') { m = 'agents'}
          if (this.props['api_name'] === 'Products') { m = 'products'}
          const searchLookup$ = this._ZohoDeskBackendService
          .searchLookup(m,  event.query)
          .subscribe(
            (resp: HttpResponse<any[]>) => {
                if (resp.status == 200) {
                  const data = [];
                  resp.body.forEach(d => {
                    data.push({
                      value: d.id,
                      label: d.value
                    });
                  });
                  this.values = data || [];
                }
                this.loading = false;
            },
          (error: HttpErrorResponse) => console.error(error));
        }
      }, 1000);
    }
  }

  reSearch(): void {
    this.isSearching = true;
    this.values = [];
  }
}
