import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-image-message',
	template: `<div class="d-flex align-items-center">
		<div class="avatar-sm me-3 ms-0">
			<div class="avatar-title bg-primary-subtle text-primary rounded font-size-20">
				<i [ngClass]="getMessageMimeTypeIconClass()"></i>
			</div>
		</div>
		<div class="d-flex-body">
			<div class="text-start">
				<h5 class="font-size-14 mb-1">{{ fileName }}</h5>
			</div>
		</div>

		<div class="ms-4">
			<ul class="list-inline mb-0 font-size-20">
				<li class="list-inline-item">
					<a href="javascript:void(0);" class="text-muted">
						<i class="ri-download-2-line" (click)="emitClick.emit()"></i>
					</a>
				</li>
			</ul>
		</div>
	</div>`,
	styles: []
})
export class DocumentMessageComponent {
	@Input() mimeType: string = '';
	@Input() fileName: string = '';

	@Output() emitClick = new EventEmitter<any>();

	//Aqui la funcion lo que debe es dar el icono de acuerdo al mime type del fichero
	getMessageMimeTypeIconClass(): string {
		switch (this.mimeType) {
			case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				return 'ri-file-word-fill';
			case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				return 'ri-file-ppt-fill';
			case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
				return 'ri-file-excel-fill';
			case 'application/x-rar-compressed':
				return 'ri-file-zip-fill';
			case 'application/x-msdownload':
				return 'ri-code-box-fill';
			case 'application/pdf':
				return 'ri-file-pdf-fill';
			default:
				return 'ri-file-line';
		}
	}
}
