import { Component, EventEmitter, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { emitData } from '../../data-access/interface/emitData.interface';

@Component({
	selector: 'app-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent {
	public srcLogo = 'assets/images/integrations/Zoho People.svg';
}
