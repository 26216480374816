import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'app-search-field',
	templateUrl: './search-field.component.html',
	styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent {
	@Input() placeholder: string = 'chatComponent.SearchHere';
	@Input() whiteBackground: boolean = false;

	@Output() emitSearchValue = new EventEmitter<string>();

	public inputValue: string = '';

	public handleFieldKeyUp(event: any | undefined): void {
		if (event.key === 'Enter' || this.inputValue.length === 0 || this.inputValue.length >= 3) {
			this.emitSearchValue.emit(this.inputValue);
		}
	}
}
