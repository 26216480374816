import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from '../../../models/emitData.interface';
import { ZohoCrmBackendService } from '../../data-access/zoho-crm-backend.service';

@Component({
  selector: 'app-record-form',
  templateUrl: './record-form.component.html',
  styleUrls: ['./record-form.component.scss']
})
export class RecordFormComponent implements OnInit, OnDestroy {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private subscriptions = new Subscription();
  public record?: any;
  public module?: string;
  sending = false;
  activeIndex = 0;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    setTimeout(() => {
      if (this.module.toLowerCase() == 'leads') {
        this.activeIndex = 0;
      } else {
        this.activeIndex = 1;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setModule(m: string) {
    this.module = m;
  }

  submit(data: emitData): void {
    this.sending = true;
    if (data.action === 'update') {
      const createRecord$ = this._ZohoCrmBackendService
        .updateRecord(this.record.id, data.values, this.module)
        .subscribe((resp: HttpResponse<any>) => {
          if (resp.status == 200) {
            if (resp.body) {
              this.emitAction.emit({
                action: 'update-record',
                values: resp.body,
                module: this.module
              });
            }
          }
          this.sending = false;
          this.subscriptions.add(createRecord$);
        }, (error: HttpErrorResponse) => {
            this.sending = false;
            this.emitAction.emit({
              action: 'error',
              values: error,
              module: data.module
            });
        });
    } else {
      if (data.action === 'create') {
        const createRecord$ = this._ZohoCrmBackendService
          .createRecord(data.values, data.module)
          .subscribe((resp: HttpResponse<any>) => {
            if (resp.status == 200) {
              if (resp.body) {
                this.emitAction.emit({
                  action: 'create-record',
                  values: resp.body,
                  module: data.module
                });
              }
            }
            this.sending = false;
            this.subscriptions.add(createRecord$);
          }, (error: HttpErrorResponse) => {
              this.sending = false;
              this.emitAction.emit({
                action: 'error',
                values: error,
                module: data.module
              });
          });
      }
    }
  }

  cancel(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: '',
      module: this.module
    });
  }

}
