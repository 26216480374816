import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { v4 as uuidv4 } from 'uuid';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements AfterViewInit {


	ngAfterViewInit(): void {

	}
}

