<div *ngIf="!loading && activities && activities.length > 0" class="list-group list-group-flush mt-2">
  <div *ngFor="let activity of activities">
    <div class="icon-container" >
      <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">

        <div class="row" >
          <div class="col-2 pt-3">
            <i style="font-size: 1.5rem" class="pi" ngClass="{{ getIcon(activity.type) }}"></i>
          </div>
          <div class="col-9 flex flex-column gap-1">
            <div class="flex flex-column gap-1">
              <span class="text-md">{{  activity.title }}</span>
              <span class="text-secondary text-sm">{{ activity.created }}</span>
              <span class="text-secondary text-sm">{{ activity.createdBy }}</span>
            </div>
          </div>
          <div class="col-1">
            <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
              <i
                (click)="confirm(activity.type, activity.id)"
                tooltipPosition="left"
                pTooltip="{{ 'Integration.Zoho.Remove' | translate }}"
                class="pi pi-trash mr-2 trans"
              ></i>
            </span>
          </div>
        </div>

      </p-card>
    </div>
</div>
</div>

<div *ngIf="!loading && (!activities || activities.length == 0)" class="card mt-5" style="text-align: center;" >
  <p>  {{ 'Integration.Zoho.There are no activities' | translate }} </p>
  <ul id="fullNames" class="list-group list-group-flush">
    <li (click)="createTask()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-file-check mr-1"></i>
      {{ 'Integration.Zoho.New task' | translate }}
    </li>
    <li (click)="createEvent()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-calendar-clock mr-1"></i>
      {{ 'Integration.Zoho.New event' | translate }}
    </li>
    <li (click)="createCall()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-phone mr-1"></i>
      {{ 'Integration.Zoho.New call' | translate }}
    </li>
  </ul>
</div>

<div *ngIf="loading" class="spinner-border" role="status">
      <span class="visually-hidden"></span>
</div>

<p-confirmDialog
  key="activityDialogDeleteConfirm"
  [position]="'right'"
  rejectButtonStyleClass="p-button-outlined" >
  <ng-template pTemplate="message" let-message>
    <div class="custom-dialog-content">
      {{ message.message }}
    </div>
  </ng-template>
</p-confirmDialog>
