import { Component, EventEmitter, HostListener, OnInit, Output, inject } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';

@Component({
	selector: 'app-contact-not-found',
	templateUrl: './contact-not-found.component.html',
	styleUrls: ['./contact-not-found.component.scss']
})
export class ContactNotFoundComponent {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  newContact() {
		this.emitAction.emit({
			action: 'new-contact',
			values: null,
      module: ''
		});
	}

  back() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}

}
