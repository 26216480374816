<li class="chats" [ngClass]="{ right: !isFriend }">
	<div *ngIf="!messageGruop.haveDate" class="conversation-list">
		<div class="chat-avatar">
			<div class="avatar-sm">
				<span *ngIf="useGeneratedImage && isFriend" class="avatar-title rounded-circle bg-primary-subtle text-primary">
					<img [src]="avatarUrl" (error)="onImageError()" (load)="onImageLoad()" alt="" />
				</span>
				<span *ngIf="!useGeneratedImage && isFriend" class="avatar-title rounded-circle bg-primary-subtle text-primary">
					{{ messageGruop.contact.charAt(0) }}
				</span>
				<span *ngIf="!isFriend" class="avatar-title rounded-circle bg-primary-subtle text-primary">
					{{ agentLogged.charAt(0) }}
				</span>
			</div>
		</div>

		<div
			class="user-chat-content w-100"
			(mouseenter)="showShortcut()"
			(touchstart)="showShortcut()"
			(mouseleave)="hideShortcut()"
			(touchend)="hideShortcut($event)"
		>
			<div class="ctext-wrap w-100">
				<ng-container *ngIf="isContentVisible || isCopied">
					<div class="p-2" [ngClass]="{ 'order-2': isFriend, 'order-1': !isFriend }">
						<i
							class="cursor-pointer pi"
							[ngClass]="{
									'pi-copy': !isCopied,
									'pi-check': isCopied,
								}"
							(click)="copyText()"
						></i>
					</div>
				</ng-container>
				<div class="ctext-wrap-content" [ngClass]="{ 'order-1': isFriend, 'order-2': !isFriend }">
					<ng-container *ngIf="messageGruop.messageType === 0">
						<p class="mb-0" [innerHTML]="messageGruop.message | filterSpecialCharactersOnMessage"></p>
					</ng-container>

					<div *ngIf="this.messageGruop.messageType === 2" class="card p-2 mb-2">
						<app-image-message
							[mimeType]="messageGruop.message.mime_type"
							[fileName]="getFileName()"
							(emitClick)="downloadFile()"
						>
						</app-image-message>
					</div>

					<div *ngIf="this.messageGruop.messageType === 3">
						<video [src]="this.messageGruop.message.url" class="card-img-top" height="180" controls></video>
					</div>

					<div *ngIf="this.messageGruop.messageType === 4 && messageGruop.message.url">
						<audio controls class="custom-audio">
							<source [src]="this.messageGruop.message.url" />
						</audio>
					</div>
					<ul
						*ngIf="this.messageGruop.messageType === 8 || this.messageGruop.messageType === 5"
						class="list-inline message-img mb-0"
					>
						<li class="list-inline-item message-img-list">
							<div>
								<a class="popup-img d-inline-block m-1 p-0" href="javascript:void(0);" title="Project 1">
									<img [src]="messageGruop.message.url" alt="" class="rounded border" (click)="openImage()" />
								</a>
							</div>
						</li>
					</ul>
					<div class="chat-time mb-0">
						<span class="align-middle">{{ getTime(messageGruop.timeStamp) }}</span>
						<div *ngIf="!isFriend" class="material-symbols-outlined icon" [ngSwitch]="messageGruop.status">
							<span *ngSwitchCase="whatsAppMessageStatus.Sending">schedule</span>
							<span *ngSwitchCase="whatsAppMessageStatus.Sent">done_all</span>
							<span *ngSwitchCase="whatsAppMessageStatus.Delivered">done_all</span>
						</div>
					</div>
				</div>
			</div>
			<div class="conversation-name">
				<span>{{ messageGruop.contact }}</span>
			</div>
		</div>
	</div>
	<div class="chat-day-title" *ngIf="messageGruop.haveDate">
		<span class="title">{{ messageGruop.date | translate }}</span>
	</div>
</li>
