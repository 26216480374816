// This file can be replaced during build by using the `fileReplacements` array.

import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
	production: false,
	logLevel: NgxLoggerLevel.TRACE,
	//SSO Configuration
	SSO_CONFIGURATION: {
		AUTHORITY: 'https://services.spellty.com/identity-service',
		CLIENT_ID: 'spellty_console',
		CLIENT_SECRET: '169c895d-ce01-4c29-9224-77fe0e51ad14',
		SCOPES: 'openid profile messaginghub.realtime offline_access',
		REDIRECT_URI: 'redirect',
		POST_LOGIN_ROUTE: '/logoutredirect',
		FORBIDDEN_ROUTE: '/forbidden',
		UNAUTHORIZED_ROUTE: '/unauthorized',
		SILENT_REDIRECT_URI: 'silent-renew.html',
		POST_LOGOUT_REDIRECT_URI: 'https://app-spellty-console.azurewebsites.net',
		SILENT_RENEW_OFFSET_IN_SECONDS: 20,
		FILTERPROTOCOLCLAIMS: true,
		LOADUSERINFO: true,
		MAX_ID_TOKEN_IAT_OFFSET_ALLOWED_IN_SECONDS: 120
	},
	//End SSO Configuration
	URL_API_SOCKET: 'https://services.spellty.com/messaginghub-service',
	URL_API_TRAFFIC: 'https://services.spellty.com/traffic-coordinator-service',
	URL_API_SUBSCRIPTION: 'https://services.spellty.com/subscription-service',
	URL_INFRASTRUCTURE_CONFIGURATOR: 'https://services.spellty.com/configurator-service',
	URL_IDENTITY_API: 'https://services.spellty.com/identity-admin-api',
	URL_API_TIME_TO_CHANGE: 'https://t2c.arqbs.com/SpringBootTime2ChangeSpellty-APP',


	URL_API_ZOHO: 'https://services.spellty.com/zoho-integration/api/v1/zoho-integration',
	URL_API_ZOHO_DESK: 'https://services.spellty.com/zoho-integration/api/v1/zoho-integration/desk',
	URL_API_AUTH_ZOHO: 'https://accounts.zoho.com/oauth/v2/auth',

	SCOPE_ZOHO_CRM: 'ZohoCRM.modules.UPDATE,ZohoCRM.modules.CREATE,ZohoCRM.modules.READ,ZohoCRM.modules.DELETE,ZohoCRM.org.READ,aaaserver.profile.ALL,ZohoCRM.settings.functions.READ,ZohoCRM.settings.functions.CREATE,ZohoCRM.settings.functions.UPDATE,ZohoCRM.notifications.UPDATE,ZohoCRM.notifications.CREATE,ZohoCRM.notifications.READ,ZohoCRM.settings.ALL,ZohoCRM.users.READ,ZohoCRM.files.create,ZohoCRM.bulk.CREATE,ZohoCRM.bulk.UPDATE,ZohoCRM.bulk.READ,ZohoCRM.coql.read,WorkDrive.files.CREATE,WorkDrive.files.UPDATE,WorkDrive.files.READ,WorkDrive.links.CREATE,WorkDrive.links.UPDATE,WorkDrive.links.READ,WorkDrive.users.READ,WorkDrive.team.READ,WorkDrive.teamfolders.CREATE',
	SCOPE_ZOHO_DESK: 'Desk.tickets.READ,Desk.tickets.WRITE,Desk.tickets.UPDATE,Desk.tickets.CREATE,Desk.contacts.READ,Desk.contacts.WRITE,Desk.contacts.UPDATE,Desk.contacts.CREATE,Desk.tasks.WRITE,Desk.tasks.READ,Desk.tasks.CREATE,Desk.tasks.UPDATE,Desk.basic.READ,Desk.basic.CREATE,Desk.settings.READ,Desk.search.READ,Desk.products.READ',
	CLIENT_ID_ZOHO_CRM: '1000.HT7Q9RR7AQUN79XW3G3MZO1TLV9QFU',
	CLIENT_ID_ZOHO_DESK: '1000.HT7Q9RR7AQUN79XW3G3MZO1TLV9QFU',
	URL_REDIRECT_ZOHO: 'https://console.spellty.com/zoho-crm-redirect',
	VISIBLE_CONTACTS: false,

  //WEB APPS
	SPELLTY_CAMPAIGN: 'https://campaigns.spellty.com/',
	SPELLTY_PORTAL: 'https://portal.spellty.com/'

};
