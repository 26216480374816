import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';

@Component({
	selector: 'app-multiples-contacts-result',
	templateUrl: './multiples-contacts-result.component.html',
	styleUrls: ['./multiples-contacts-result.component.scss']
})
export class MultiplesContactsResultComponent implements OnInit {

	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  public contacts?: any[];

  ngOnInit(): void {
  }

  selected(contact: any) {
      this.emitAction.emit({
          action: 'selected',
          values: contact,
          module: ''
      });
  }

  back() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}
}
