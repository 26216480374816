import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TooltipModule } from 'primeng/tooltip';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { SidebarComponent } from '../ui/sidebar/sidebar.component';
import { LayoutComponent } from './layout.component';

const primeNGModules = [BadgeModule, TooltipModule, MenuModule, ButtonModule];

@NgModule({
	declarations: [LayoutComponent, SidebarComponent],
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule,
		...primeNGModules,
		NgProgressModule.withConfig({ color: '#675af3', spinner: false }),
		NgProgressHttpModule
	]
})
export class LayoutModule {}
