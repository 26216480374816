import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ConversationEventService {
	private eventSubject = new Subject<any>();
	private transferConversation = new Subject<any>();

	emitEvent(data: any) {
    this.storageSelectedChat(data);
		this.eventSubject.next(data);
	}

	getEvent() {
		return this.eventSubject.asObservable();
	}

	emitEventTransfer(data: any) {
		this.transferConversation.next(data);
	}

	getEventTransfer() {
		return this.transferConversation.asObservable();
	}

  storageSelectedChat(data: any): void {
    localStorage.setItem('selectedChatConversation', JSON.stringify(data));
  }
}
