import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'urlLink'
})
export class UrlLinkPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}

	transform(value: string): SafeHtml {
		if (!value) return value;

		const urlRegex = /((https?:\/\/[^\s]+))/g;
		const formattedMessage = value.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
		return this.sanitizer.bypassSecurityTrustHtml(formattedMessage);
	}
}
