<div class="container-ttc mb-2">
	<div class="body-ttc">

    <div class="row mt-1 mr-1">
      <div class="col-2 pl-4">
        <i pTooltip="{{ 'Integration.Zoho.Exit' | translate }}"
           tooltipPosition="left"
           style="float: left;" (click)="handleCloseTTC()" class="pi pi-times cursor-pointer"></i>
      </div>
      <div class="col-9 flex justify-content-center">
        <img class="mr-2" src="assets/images/integrations/Zoho People.svg" width="18" style="margin-top: -5px;" />
        <h5>TimeToChange</h5>
      </div>
      <div class="col-1">
          <i
            (click)="logout()"
            *ngIf="isAuth"
            tooltipPosition="left"
            pTooltip="{{ 'timeToChange.Log out' | translate }}"
            style="cursor: pointer; float: right;"
          class="pi pi-sign-out"
          ></i>
      </div>
    </div>
    <hr class="header" />

		<div #childTTCComponents></div>
	</div>
</div>

<swal
	#loginSwal
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="Bienvenid@ Mesa"
	icon="success"
>
</swal>

<swal
	#saveContactSwal
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="Se ha creado el contacto exitosamente."
	icon="success"
>
</swal>

<swal
	#saveAccountSwal
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="Se ha creado el contacto exitosamente."
	icon="success"
>
</swal>
