import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { MessageService } from '../../utils/messages.service';
import { LoadingZohoCrmComponent } from '../../ui/loading/loading.component';
import { Organization } from '../../models/organization-response.model';
import { ResendConversationEventService } from '../../service/resend-conversation-event.service';
import { IntegrationConfigResponse } from '../../models/integration-config-response.model';
import { IntegrationConfigComponent } from '../../zoho-crm/ui/integration-config/integration-config.component';
import { LayoutMetadata } from '../../models/layout-metadata.model';
import { ZohoDeskBackendService } from '../data-access/zoho-desk-backend.service';
import { MultiplesContactsResultComponent } from '../ui/multiples-contacts-result/multiples-contacts-result.component';
import { ContactDeskFormComponent } from '../ui/contact-desk-form/contact-desk-form.component';
import { InitZohoDeskComponent } from '../ui/init-zoho-desk/init-zoho-desk.component';
import { TicketDeskListComponent } from '../ui/ticket/list/ticket-list.component';
import { ContactNotFoundComponent } from '../ui/contact-not-found/contact-not-found.component';
import { TicketDeskFormComponent } from '../ui/ticket/form/ticket-form.component';
import { TicketsDetailsComponent } from '../ui/ticket/details/tickets-details.component';
import { Message } from 'primeng/api';

@Component({
	selector: 'app-zoho-desk',
	templateUrl: './zoho-desk.component.html',
	styleUrls: ['./zoho-desk.component.scss']
})
export class ZohoDeskComponent implements OnInit, OnDestroy {

	serviceInstance: IServiceInstance;

	@ViewChild('childZohoDeskComponents', { read: ViewContainerRef }) container!: ViewContainerRef;

  @Output() emitAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() closeZCRM: EventEmitter<any> = new EventEmitter<any>();

	private _ResendConversationEventService = inject(ResendConversationEventService);
	private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
	public translate = inject(TranslateService);
	private messageService = inject(MessageService);
	loadingActive = false;
	showNavBar = false;
	serviceInstanciaId: string;
	public numberPhone = '';
	public fullName = '';
	private subscriptions = new Subscription();

  loading = false;
  loadingDepartment = false;
  departments: any[] = [];
  currentDepartment: any ;
  currentDepartmentId: string;
  selectedSearchOption = '1';
  searchInput: string = '';
  placeHolder = 'Search contact by email, phone or name' ;

  messages: Message[] | undefined;

  options: { label: string, value: string }[] = [];

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	ngOnInit(): void {
    this.loadOptions();
    this.messages = [{ severity: 'error', detail: this.translate.instant('Integration.Zoho.The departments are not loaded. Try it again') }];
		this.serviceInstanciaId = this.serviceInstance.id;
    this.subscribeConversationEvent();
		this.checkIfShowNavBar();
		setTimeout(() => {
      if (localStorage.getItem('ZohoDeskOrgDomainName') == null) {
        this.registerIntegration()
      } else {
        this.checkChatSelected();
      }
    });
    this.translate.onLangChange.subscribe(() => {
      this.loadOptions();
    });
	}

  loadOptions(): void {
    this.translate.get([
      'Integration.Zoho.Contact',
      'Integration.Zoho.Ticket',
      'Integration.Zoho.ID Ticket',
      'Integration.Zoho.Agent'
    ]).subscribe(translations => {
      this.options = [
        { label: translations['Integration.Zoho.Contact'], value: '1' },
        { label: translations['Integration.Zoho.Ticket'], value: '2' },
        { label: translations['Integration.Zoho.ID Ticket'], value: '3' },
        { label: translations['Integration.Zoho.Agent'], value: '4' }
      ];
    });
  }

  checkChatSelected(): any {
    const chat: any = localStorage.getItem('selectedChatConversation');
    if (!chat) {
      this.getDepartaments(false);
      return;
    }
    if (chat) {
      const queryChatActive = document.querySelectorAll('.chat-user-list li.active');
      if (queryChatActive.length > 0) {
        this.numberPhone = JSON.parse(chat).clientPhone;
        this.fullName = JSON.parse(chat).fullName;
        this.container.clear();
        this.getDepartaments(true);
        return;
      } else {
        this.getDepartaments(false);
      }
    }
  }

  getDepartaments(searchContact: boolean): void {
    this.loading = true;
    this.loadingDepartment = true;
    const getDepartament$ = this._ZohoDeskBackendService
    .getDepartaments()
    .subscribe((resp: HttpResponse<any[]>) => {
        if (resp.status == 200) {
          this.departments = resp.body;
          if (this.departments && this.departments.length > 0) {
            this.currentDepartment = this.departments[0];
            this.currentDepartmentId = this.currentDepartment.id;
            this.loadingDepartment = false;
            if (searchContact) {
              this.container.clear();
              this.searchInZoho(this.numberPhone);
            } else {
              this.initZohoCRM();
            }
          }
        }
        this.loading = false;
        this.subscriptions.add(getDepartament$);
    }, (error: HttpErrorResponse) => {
      this.loadingDepartment = false;
      this.errorHandler(error);
    });
  }

  initZohoCRM(): void {
    this.container.clear();
    if (this.departments.length > 0) {
      const initZohoCRMComponent = this.container.createComponent(InitZohoDeskComponent);
      const initZohoCRMEvent$ = initZohoCRMComponent.instance.emitAction.subscribe((values) => {
        this.subscriptions.add(initZohoCRMEvent$);
        if (values.action === 'new-ticket') {
          this.crearTicket();
        } else {
          if (values.action === 'open-tickets') {
            this.openTickets('OPEN', 'Integration.Zoho.Open tickets', '');
          } else {
            if (values.action === 'unassigned-tickets') {
              this.openTickets('UNASSIGNED', 'Integration.Zoho.Unassigned tickets', '');
            }
          }
        }
      });
    }
	}

  openTickets(status: string, title: string, id: string): void {
		this.container.clear();
		const ticketListComponent = this.container.createComponent(TicketDeskListComponent);
    ticketListComponent.instance.status = status;
    ticketListComponent.instance.title = title;
    ticketListComponent.instance.id = id;
    ticketListComponent.instance.department = this.currentDepartment;
		const ticketListEvent$ = ticketListComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(ticketListEvent$);
			if (values.action === 'cancel') {
				this.initZohoCRM();
			}
      if (values.action === 'new-ticket') {
				this.crearTicket();
			}
      if (values.action === 'copy-url') {
        this.messageService.showMessage('Ticket URL has been copied', 'success', false);
			}
      if (values.action === 'ticket-details') {
        this.ticketDetails(values.values, status, title, id);
      }
      if (values.action === 'error') {
				this.errorHandler(values.values);
			}
		});
	}

  subscribeConversationEvent() {
		const chatEvent$ = this._ResendConversationEventService.getEvent().subscribe((data) => {
      this.subscriptions.add(chatEvent$);
      if (localStorage.getItem('activeZoho') === 'desk') {
        if (!this.checkIfStartConfiguration()) {
          this.numberPhone = data.clientPhone;
          this.fullName = data.fullName;
          this.container.clear();
          this.searchInZoho(this.numberPhone);
        } else {
          this.startIntegrationConfig();
        }
      }
		});
	}

	searchInZoho(phone: string): void {
		if (!phone) {
			this.initZohoCRM();
			return;
		}
		this.loadingZohoCRM();
		const searchRecords$ = this._ZohoDeskBackendService.searchContacts(phone).subscribe(
			(resp: HttpResponse<any[]>) => {
				this.processedSearching(resp, 'phone');
				this.subscriptions.add(searchRecords$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

  searchContactsByTerm(term: string): void {
    this.loadingZohoCRM();
		const searchRecords$ = this._ZohoDeskBackendService.searchContactsByTerm(term).subscribe(
			(resp: HttpResponse<any[]>) => {
				this.processedSearching(resp, 'term');
				this.subscriptions.add(searchRecords$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

  processedSearching(resp: HttpResponse<any[]>, from: string): void {
    if (resp.status == 200) {
      this.loadingActive = false;
      const response: any[] = resp.body;
      if (!response || response.length == 0) {
        this.contactNotFound();
      } else {
        if (response.length == 1) {
          this.openTickets('CONTACT', 'Integration.Zoho.Contact Tickets', response[0].id)
        } else {
          this.selectedOnMultiplesContacts(response);
        }
      }
    }
  }

	loadingZohoCRM(): void {
		this.container.clear();
		this.loadingActive = true;
		this.container.createComponent(LoadingZohoCrmComponent);
	}

	registerIntegration(): void {
		this.loadingZohoCRM();
		const registerIntegration$ = this._ZohoDeskBackendService.registerIntegration().subscribe(
			(resp: HttpResponse<Organization>) => {
				this.loadingActive = false;
				if (resp.status == 200) {
          // this.getDepartaments();
          this.startIntegrationConfig();
					localStorage.setItem('ZohoDeskOrgDomainName', resp.body.domainName);
          localStorage.setItem('ZohoDeskPortalName', resp.body.portalName);
				}
				this.subscriptions.add(registerIntegration$);
			},
			(error: HttpErrorResponse) => this.errorHandler(error)
		);
	}

  startIntegrationConfigComponent(integrationConfigResponse: IntegrationConfigResponse): void {
		this.container.clear();
		const onIntegrationConfigComponent = this.container.createComponent(IntegrationConfigComponent);
		onIntegrationConfigComponent.instance.integrationConfigResponse = integrationConfigResponse;
    onIntegrationConfigComponent.instance.zohoType = 'desk';
		const onLoginEvent$ = onIntegrationConfigComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(onLoginEvent$);
			if (values.action === 'save-init-config') {
				const registerIntegration$ = this._ZohoDeskBackendService
					.registerIntegrationConfig(values.values)
					.subscribe(
						(res: HttpResponse<LayoutMetadata>) => {
							const layoutMetaData: LayoutMetadata = res.body;
							localStorage.setItem('tickets-metadata-desk', JSON.stringify(layoutMetaData.ticketsSections));
              localStorage.setItem('contacts-metadata-desk', JSON.stringify(layoutMetaData.clientsSections));
							this.messageService.showMessage('The settings have been saved.', 'success', true);
							this.container.clear();
							this.getDepartaments(false);
							this.subscriptions.add(registerIntegration$);
						},
						(error: HttpErrorResponse) => this.errorHandler(error)
					);
			}
      if (values.action === 'logout') {
        this.logout();
      }
      if (values.action === 'init') {
        this.container.clear();
        this.initZohoCRM();
      }
    });
	}

	startIntegrationConfig() {
		this.loadingZohoCRM();
			const calls: any[] = [];
			calls.push(this._ZohoDeskBackendService.getLayoutsConfig());
			const forkJoin$ = forkJoin(calls).subscribe(
				(data: any[]) => {
				this.loadingActive = false;
				const integrationConfigResponse: IntegrationConfigResponse = data[0].body;
				if (integrationConfigResponse && integrationConfigResponse.layouts) {
					localStorage.setItem('zohoDeskLayouts', JSON.stringify(integrationConfigResponse.layouts));
				}
				this.startIntegrationConfigComponent(integrationConfigResponse);
				this.subscriptions.add(forkJoin$);
				},
				(error: HttpErrorResponse) => this.errorHandler(error)
			);
	}

	errorHandler(error: HttpErrorResponse): void {
		if (error && error.error && error.error.message && error.error.code) {
			const errorMessage: string = error.error.message;
			const errorCode: string = error.error.code;
			if (
        errorCode.startsWith('error.zohocrm.auth') ||
        errorCode.startsWith('INVALID TOKEN ERROR') ||
        errorCode.startsWith('unauthorized.access') ||
        errorCode.startsWith('401') ||
        errorCode.startsWith('403') ||
        errorCode.startsWith('500') ||
        errorCode.startsWith('error.zohocrm.agent.not.contain.the.integration')
      ) {
				this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
			} else {
        this.messageService.showMessage(errorMessage, 'error', false);
        if (this.loadingActive) {
          this.initZohoCRM();
        }
      }
		} else {
			this.messageService.showMessage('An error has occurred, try again', 'error', false);
			this.initZohoCRM();
		}
	}

	checkIfShowNavBar(): void {
		this.showNavBar = localStorage.getItem('zohoDeskCredencials') != null;
	}

  checkIfStartConfiguration(): boolean {
		return localStorage.getItem('tickets-metadata-desk') == null;
	}

	logout(): void {
		this.emitAction.emit({
      action: 'logout',
      values: '',
      module: ''
    });
	}

  selectedOnMultiplesContacts(contacts: any[]): void {
		this.container.clear();
		const recordMultiplesRecordsComponent = this.container.createComponent(MultiplesContactsResultComponent);
		recordMultiplesRecordsComponent.instance.contacts = contacts;
		const recordMultiplesRecordsEvent$ = recordMultiplesRecordsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(recordMultiplesRecordsEvent$);
			if (values.action === 'selected') {
        this.openTickets('CONTACT', 'Integration.Zoho.Contact Tickets', values.values.id);
			} else {
				this.initZohoCRM();
			}
		});
	}

  search(): void {
    if (this.searchInput && this.searchInput.length > 2) {
      if (this.selectedSearchOption === '1') {
        this.searchContactsByTerm(this.searchInput);
      }
      if (this.selectedSearchOption === '2') {
        this.openTickets('SEARCH', 'Integration.Zoho.Search tickets', this.searchInput);
      }
      if (this.selectedSearchOption === '3') {
        this.openTickets('TICKET-NUMBER', 'Ticket #' + this.searchInput, this.searchInput);
      }
      if (this.selectedSearchOption === '4') {
        this.openTickets('SEARCH-AGENT', 'Integration.Zoho.Agent Tickets', this.searchInput);
      }
    } else {
      this.messageService.showMessage('Enter at least 3 letters', 'warning', true);
    }
	}

  chanceTooltip(): void {
    if (this.selectedSearchOption === '1') {
      this.placeHolder = 'Search contact by email, phone or name' ;
    }
    if (this.selectedSearchOption === '2') {
      this.placeHolder = 'Search ticket by email, phone or subject' ;
    }
    if (this.selectedSearchOption === '3') {
      this.placeHolder = 'Search ticket by number' ;
    }
    if (this.selectedSearchOption === '4') {
      this.placeHolder = 'Search agent by email, phone or name' ;
    }
    this.search();
  }

  updateDepartment(): void{
    console.error('Current: ', this.currentDepartmentId);
  }

  contactNotFound(): void {
		this.container.clear();
		const contactNotFoundComponent = this.container.createComponent(ContactNotFoundComponent);
		const contactNotFoundEvent$ = contactNotFoundComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(contactNotFoundEvent$);
			if (values.action === 'new-contact') {
				this.createContact();
			} else {
        if (values.action === 'cancel') {
          this.initZohoCRM();
        }
      }
		});
	}

  crearTicket(): void {
		this.container.clear();
		const ticketDeskFormComponent = this.container.createComponent(TicketDeskFormComponent);
    ticketDeskFormComponent.instance.currentDepartmentId = this.currentDepartment.id;
		const ticketDeskFormEvent$ = ticketDeskFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(ticketDeskFormEvent$);
			if (values.action === 'cancel') {
				this.initZohoCRM();
			}
      if (values.action === 'created') {
        this.messageService.showMessage('The record has been created.', 'success', true);
				this.initZohoCRM();
			}
      if (values.action === 'error') {
        this.errorHandler(values.values);
      }
		});
	}

  ticketDetails(ticket: any, status: string, title: string, id: string): void {
		this.container.clear();
		const ticketsDetailsComponent = this.container.createComponent(TicketsDetailsComponent);
    ticketsDetailsComponent.instance.ticket = ticket;
    ticketsDetailsComponent.instance.currentDepartmentId = this.currentDepartmentId;
		const ticketsDetailsEvent$ = ticketsDetailsComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(ticketsDetailsEvent$);
			if (values.action === 'cancel') {
				this.openTickets(status, title, id);
			}
      if (values.action === 'error') {
        this.errorHandler(values.values);
      }
		});
	}

  createContact(): void {
		this.container.clear();
		const contactDeskFormComponent = this.container.createComponent(ContactDeskFormComponent);
		const contactDeskFormEvent$ = contactDeskFormComponent.instance.emitAction.subscribe((values) => {
			this.subscriptions.add(contactDeskFormEvent$);
			if (values.action === 'cancel') {
				this.initZohoCRM();
			}
      if (values.action === 'created-ticket') {
        this.messageService.showMessage('The record has been created.', 'success', true);
				this.initZohoCRM();
			}
      if (values.action === 'error') {
        this.errorHandler(values.values);
      }
		});
	}

  back(): void {
    this.emitAction.emit({
      action: 'close',
      values: '',
      module: ''
    });
  }

  showSearchBar(): boolean {
    return localStorage.getItem('tickets-metadata-desk') == null;
  }

}
