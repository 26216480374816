

<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-1">
      <i
        (click)="back()"
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
      ></i>
    </div>
    <div class="col-9">
      {{ 'Integration.Zoho.Search record' | translate }}
    </div>
  </div>
</h6>

<div *ngIf="contacts && contacts.length > 0" >
  <div (click)="selected(contact)" class="icon-container" *ngFor="let contact of contacts">
    <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">
      <div class="flex justify-content-between gap-5">
        <div class="flex flex-column gap-1">
            <span class="text-md">   {{ contact.firstName }} {{ contact.lastName }}</span>
            <span class="text-secondary text-sm"> {{ contact.mobile }}</span>
            <span class="text-secondary text-sm"> {{ contact.secondaryEmail }} </span>
        </div>
      </div>
    </p-card>
  </div>
</div>



<!--
<div class="container-body mt-3">
    <div class="card">
      <div class="card-header">
        {{ 'Integration.Zoho.contacts' | translate }}
      </div>
      <ul id="fullNames" class="list-group list-group-flush">
        <li style="cursor: pointer;" *ngFor="let contact of contacts" class="list-group-item list-group-item-action" (click)="selected(contact)">
           {{ contact.firstName }} {{ contact.lastName }}
        </li>
      </ul>
    </div>
  </div>
  -->
