import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-ticket-filter',
  templateUrl: './ticket-filter.component.html',
  styleUrls: ['./ticket-filter.component.css']
})
export class TicketFilterComponent {

  @Output() filterEmiter: EventEmitter<any> = new EventEmitter<any>();

  isPopupVisible = false;
  statusTypeOptions = [
    {
      label: 'Open',
      value: 'Open'
    },
    {
      label: 'On Hold',
      value: 'On Hold'
    },
    {
      label: 'Escalated',
      value: 'Escalated'
    },
    {
      label: 'Closed',
      value: 'Closed'
    }
  ];
  channelTypeOptions = [
    {
      label: 'Twitter',
      value: 'Twitter'
    },
    {
      label: 'Phone',
      value: 'Phone'
    },
    {
      label: 'Email',
      value: 'Email'
    },
    {
      label: 'Facebook',
      value: 'Facebook'
    },
    {
      label: 'Web',
      value: 'Web'
    },
    {
      label: 'Chat',
      value: 'Chat'
    },
    {
      label: 'Forums',
      value: 'Forums'
    },
    {
      label: 'Feedback Widget',
      value: 'Feedback Widget'
    }
  ];
  selectedStatus = '';
  selectedChannel = '';

  togglePopup() {
    this.isPopupVisible = !this.isPopupVisible;
  }

  emitter(): void {
    this.filterEmiter.emit({
			statusFilter: this.selectedStatus,
			channelFilter: this.selectedChannel,
		});
    this.isPopupVisible = false;
  }

  filter(): void {
    this.emitter();
  }

  cancel():void {
    this.selectedStatus = '';
		this.selectedChannel = '';
    this.emitter();
  }

}
