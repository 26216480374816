
<div class="card mt-5" >
  <ul id="fullNames" class="list-group list-group-flush">
    <li (click)="openTickets()" class="list-group-item list-group-item-action" >
      <i class="pi pi-folder-open mr-1"></i>
      {{ 'Integration.Zoho.Open tickets' | translate }}
    </li>
    <li (click)="unassignedTickets()" class="list-group-item list-group-item-action" >
      <i class="pi pi-folder-plus mr-1"></i>
      {{ 'Integration.Zoho.Unassigned tickets' | translate }}
    </li>
    <li (click)="newTicket()" class="list-group-item list-group-item-action" >
      <i class="pi pi-plus-circle mr-1"></i>
      {{ 'Integration.Zoho.Create ticket' | translate }}
    </li>
  </ul>
</div>
