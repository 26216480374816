import { Component, EventEmitter, OnInit, Output, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { emitData } from '../../data-access/interface/emitData.interface';
import { cityCode, cityCodes } from '../../utils/codesData/cityCodes';
import { identificationTypes } from '../../utils/codesData/identificationTypes';
import { stateCodes } from '../../utils/codesData/stateCodes';

@Component({
	selector: 'app-contact-form',
	templateUrl: './contact-form.component.html',
	styleUrls: ['./contact-form.component.scss'],
	providers: []
})
export class ContactFormComponent implements OnInit {
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

	private fb = inject(FormBuilder);

	public form = this.fb.nonNullable.group({
		firstname: ['', [Validators.required]],
		lastname: ['', [Validators.required]],
		mobile_number: ['', [Validators.required]],
		email: ['', [Validators.required]],
		identification_type_id: ['', [Validators.required]],
		identification: ['', [Validators.required]],
		account_id: [''],
		state: ['', [Validators.required]],
		city: ['', [Validators.required]],
		country: ['COL']
	});

	public options = { autoHide: false, scrollbarMinSize: 100 };
	public contact_exists = false;
  public contact: any | undefined;
	public search: boolean;
	public accountName: string | undefined;

	//information burned
	public departments = stateCodes;
	public municipalities = cityCodes;
	public municipalitiesDepartment: cityCode[];
	public identificationType = identificationTypes;

	ngOnInit(): void {
		this.form.controls.state.valueChanges.subscribe((value) => {
			this.filterCity(Number(value));
		});
	}

	accountContentAsig(content: string | undefined) {
		this.form.patchValue({
			account_id: content
		});
	}

	createContact() {
		this.emitAction.emit({
		  action: 'create contact',
		 	values: this.form.value
		});
	}

	activeTicket() {
		this.emitAction.emit({
			action: 'create ticket',
			values: null
		});
	}

	viewTickets() {
		this.emitAction.emit({
			action: 'view ticket',
			values: null
		});
	}

	createAccount() {
		this.emitAction.emit({
			action: 'create account',
			values: null
		});
	}

	backToLogin(): void {
		this.emitAction.emit({
			action: 'back login',
			values: null
		});
	}

	filterCity(idState: number) {
		this.municipalitiesDepartment = this.municipalities.filter((obj) => {
			return obj.codigoDepartamento === idState;
		});
	}

  getTextByValue(value: string): string | undefined {
    if (!value) { return ''; }
    const item = identificationTypes.find((type) => type.value === Number(value));
    return item ? item.text : '';
  }

  getCityTextByValue(value: string): string | undefined {
    if (!value) { return ''; }
    const city = cityCodes.find((city) => city.value === Number(value));
    return city ? city.text : '';
  }

  getDepartmentByCodigo(codigoDepartamento: string): string | undefined {
    if (!codigoDepartamento) { return ''; }

    const department = cityCodes.find((city) => city.codigoDepartamento === Number(codigoDepartamento));
    return department ? department.nombreDepartamento : '';
  }

}
