<div class="contact-container mb-3">
	<span class="title text-center">
		{{ contact_exists ? 'Contacto' : 'Crear Contacto' }}
	</span>
	<div *ngIf="contact_exists" class="flex flex-row gap-3">
		<div class="col-md-6-custom text-center">
			<button type="button" class="btn btn-primary btn-sm" (click)="activeTicket()">Crear Tickets</button>
		</div>
		<div class="col-md-6-custom text-center">
			<button type="button" class="btn btn-secondary btn-sm" (click)="viewTickets()">Ver Tickets</button>
		</div>
	</div>

	<form class="form-contact" [formGroup]="form">
		<div class="flex flex-column gap-1">
			<label class="label">Nombres</label>
			<input type="text" pInputText class="form-control" formControlName="firstname" />
		</div>
		<div class="flex flex-column gap-1">
			<label class="label">Apellidos</label>
			<input type="text" pInputText class="form-control" formControlName="lastname" />
		</div>
		<div class="flex flex-column gap-1">
			<label class="label">Telefono Movil</label>
			<input type="text" pInputText class="form-control" formControlName="mobile_number" />
		</div>
		<div class="flex flex-column gap-1">
			<label class="label">Email </label>
			<input
        type="text"
        pInputText
        class="form-control"
        formControlName="email"
      />
		</div>
		<div class="flex flex-column gap-1">
			<label class="label">Tipo de identificación</label>
      <input
        *ngIf="contact_exists"
        type="text"
        pInputText
        class="form-control"
        readonly
        [value]="getTextByValue(contact.identification_type_id)"
      />

      <p-dropdown
        *ngIf="!contact_exists"
				[options]="identificationType"
				optionLabel="text"
				optionValue="value"
				formControlName="identification_type_id"
			/>
		</div>
		<div class="flex flex-column gap-1">
			<label class="label">Identificación</label>
			<input type="text" pInputText class="form-control" formControlName="identification" />
		</div>


    <div *ngIf="contact_exists" class="flex flex-column gap-1">
			<label class="label">Busqueda de compañia</label>
			<input
        type="text"
        pInputText
        class="form-control"
        readonly
        [value]="contact.account.name"
      />
		</div>
		<app-search-field-ttc
      *ngIf="!contact_exists"
			[accountType]="'Todos'"
			[placeholder]="'Busqueda de compañia'"
			[accountName]="accountName"
			(accountContent)="accountContentAsig($event)"
		></app-search-field-ttc>

		<button type="button" class="btn btn-primary btn-sm" (click)="createAccount()">Crear Cuenta</button>

		<div class="flex flex-column gap-1">
			<label class="label">Departamento</label>
      <input
        *ngIf="contact_exists"
        type="text"
        pInputText
        class="form-control"
        readonly
        [value]="getDepartmentByCodigo(contact.state)"
      />
			<p-dropdown *ngIf="!contact_exists" [options]="departments" optionLabel="text" optionValue="value" formControlName="state" />
		</div>
		<div class="flex flex-column gap-1">
			<label class="label">Ciudad</label>
      <input
        *ngIf="contact_exists"
        type="text"
        pInputText
        class="form-control"
        readonly
        [value]="getCityTextByValue(contact.city)"
      />
			<p-dropdown *ngIf="!contact_exists" [options]="municipalitiesDepartment" optionLabel="text" optionValue="value" formControlName="city" />
		</div>
	</form>

	<div class="flex flex-row gap-3">
    <button [disabled]="contact_exists" type="button" class="btn btn-primary btn-sm" (click)="createContact()">Crear</button>
	</div>
</div>
