import { Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { emitData } from '../../../models/emitData.interface';

@Component({
	selector: 'app-init-zoho-crm',
	templateUrl: './init-zoho-crm.component.html',
	styleUrls: ['./init-zoho-crm.component.scss']
})
export class InitZohoCrmComponent {

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  showLeads() {
		this.emitAction.emit({
			action: 'show-leads',
			values: null,
      module: ''
		});
	}

  showContacts() {
		this.emitAction.emit({
			action: 'show-contacts',
			values: null,
      module: ''
		});
	}

  createContact() {
		this.emitAction.emit({
			action: 'create-contact',
			values: null,
      module: ''
		});
	}

  createLead() {
		this.emitAction.emit({
			action: 'create-lead',
			values: null,
      module: ''
		});
	}

}
