<div *ngIf="loading" class="spinner-border mt-5" role="status">
  <span class="visually-hidden"></span>
</div>

<div *ngIf="!loading && deals && deals.length > 0" class="list-group list-group-flush">
  <div *ngFor="let deal of deals">
    <div class="icon-container" >
      <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px', borderBottom: '1px;' }">
        <div class="flex justify-content-between gap-5">
          <div class="flex flex-column gap-1">
            <div class="flex flex-column gap-1">
              <span class="text-md">{{  deal.name }}</span>
              <span class="text-secondary text-sm">{{ deal.stage }}</span>
            </div>
          </div>
          <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
            <i
              (click)="confirm(deal.id)"
              tooltipPosition="left"
              pTooltip="{{ 'Integration.Zoho.Remove' | translate }}"
              class="pi pi-trash mr-2 trans"
            ></i>
          </span>
        </div>
        <div class="row">
          <div class="col-3" pTooltip="{{ 'Integration.Zoho.Probability' | translate }}">
            <i class="pi pi-wave-pulse mr-1"></i> <span class="text-md">{{  deal.probability }} %</span>
          </div>
          <div class="col-4" pTooltip="{{ 'Integration.Zoho.Amount' | translate }}">
            <i class="pi pi-dollar mr-1"></i> <span class="text-md">{{  deal.amount }}</span>
          </div>
          <div class="col-4" pTooltip="{{ 'Integration.Zoho.Closing date' | translate }}">
            <i class="pi pi-calendar-times mr-1"></i> <span class="text-md">{{  deal.closingDate }}</span>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>

<span *ngIf="!loading && (!deals || deals.length == 0)" class="mt-2">
  <div *ngIf="!show" class="card mt-5" style="text-align: center;" >
    <p> {{ 'Integration.Zoho.There are no opportunities' | translate }} </p>
    <ul id="fullNames" class="list-group list-group-flush">
      <li style="cursor: pointer;" (click)="showForm()" class="list-group-item list-group-item-action" >
        <i
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.new deal' | translate }}"
          class="pi pi-plus-circle mr-1"
        ></i>
        {{ 'Integration.Zoho.new deal' | translate }}
      </li>
    </ul>
  </div>
</span>

<p-confirmDialog
  key="dealDialogDeleteConfirm"
  [position]="'right'"
  rejectButtonStyleClass="p-button-outlined" >
  <ng-template pTemplate="message" let-message>
    <div class="custom-dialog-content">
      {{ message.message }}
    </div>
  </ng-template>
</p-confirmDialog>
