  <h6  class="mb-1 p-3" >
    <div class="row">
      <div class="col-10">
        <span>{{ 'Integration.Zoho.Deals' | translate }}</span>
      </div>
      <div class="col-1">
        <i
          (click)="create()"
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.new deal' | translate }}"
          class="pi pi-plus mr-5"
        ></i>
      </div>

      <div class="col-1">
        <i
          (click)="refreshList(true)"
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.Update' | translate }}"
          class="pi pi-refresh mr-1"
        ></i>
      </div>

    </div>
  </h6>
  <div #childDealsComponents></div>

