import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';
import { ZohoDeskBackendService } from '../../../../data-access/zoho-desk-backend.service';

@Component({
  selector: 'app-tickets-comments',
  templateUrl: './tickets-comments.component.html',
  styleUrls: ['./tickets-comments.component.scss']
})
export class TicketsCommentsComponent implements OnDestroy, OnInit {

	@Input() ticketId: string = '';

  private _ZohoDeskBackendService = inject(ZohoDeskBackendService);
  private messageService = inject(MessageService);

  loadingComments = false;
  comments: any[] = [];
  private subscriptions = new Subscription();

  comment: string = '';
  isPrivate: boolean = true;
  saving = false;

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    setTimeout(() => {
      this.getComments(false);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  back(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: '',
      module: ''
    });
  }

  getComments(update: boolean): void {
    this.loadingComments = true ;
    const getCommentsByTicketsEvent$ = this._ZohoDeskBackendService
    .getCommentsByTickets(this.ticketId, update)
    .subscribe(
        (resp: HttpResponse<any[]>) => {
            if (resp.status == 200) {
              this.comments = resp.body;
              this.loadingComments = false ;
            }
            this.subscriptions.add(getCommentsByTicketsEvent$);
        },
        (error: HttpErrorResponse) => this.errorHandler(error));
	}

  addComment(): void {
    this.saving = true;
    if (this.comment === '') {
      this.messageService.showMessage('You must enter a comment', 'warning', false);
    } else {
      const saveNote$ = this._ZohoDeskBackendService
        .saveComment({
          comment: this.comment,
          ticketId: this.ticketId,
          isPrivate: this.isPrivate,
        })
        .subscribe(() => {
            this.subscriptions.add(saveNote$);
            this.getComments(true);
            this.messageService.showMessage('A comment has been created on the ticket', 'success', false);
            this.saving = false;
            this.comment = '';
        },
        (error: HttpErrorResponse) => {
          this.errorHandler(error);
        });
    }
	}

  errorHandler(error: HttpErrorResponse) {
    this.loadingComments = false ;
    this.saving = false;
		this.emitAction.emit({
			action: 'error',
			values: error,
      module: ''
		});
	}

}
