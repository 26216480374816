import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { catchError, finalize, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateContactData } from '../interface/create-contact.interface';
import { LinkContactToAccount } from '../interface/link-contactToAccount.interface';
import { Contact, SearchContact } from '../interface/search-contact.type';
import { AuthenticationTTCService } from './authentication-ttc.service';

@Injectable({
	providedIn: 'root'
})
export class ContactTTCService {
	private _http = inject(HttpClient);
	private _AuthenticationTTCService = inject(AuthenticationTTCService);

	private baseUrl = environment.URL_API_TIME_TO_CHANGE;

	createContact(contact: Contact): Observable<CreateContactData> {
		const URL = this.baseUrl + '/api/v1/contact';

		const headers = new HttpHeaders()
			.set('Authorization', 'Bearer ' + this._AuthenticationTTCService.token)
			.set('Content-Type', 'application/json');

		//this._SpinnerService.invokeSpinner();

		return this._http
			.post<CreateContactData>(URL, contact, {
				headers: headers
			})
			.pipe(
				map((response) => {
					return response;
				}),
				finalize(() => {
					// this._SpinnerService.removeSpinner();
				})
			);
	}

	//Servicio para asociar contacto a cuenta -> linkContactToAccount
	linkContactToAccount(account_id: number, contact_id: string): Observable<LinkContactToAccount> {
		const URL = this.baseUrl + '/api/v1/contact/linkContactToAccount';

		let body = {
			account_id: account_id,
			contact_id: contact_id
		};

		return this._http.post<LinkContactToAccount>(URL, body).pipe(
			map((response) => {
				return response;
			})
		);
	}

	searchContact(numberPhone: string): Observable<SearchContact> {
		const URL = this.baseUrl + '/api/v1/contact';
		return this._http.get<SearchContact>(`${URL}/${numberPhone}`).pipe(
			map((response) => {
				return response;
			})
		);
	}
}
