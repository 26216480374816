
<div class="row mt-1">
  <div class="col-1 pl-4">
    <i pTooltip="{{ 'Integration.Zoho.Exit' | translate }}"
       tooltipPosition="left"
       style="float: left;" (click)="back()" class="pi pi-times cursor-pointer"></i>
  </div>
</div>

<div class="px-1 row">
	<div class="container-body">
		<h3 class="text-muted fw-normal mb-0">
      {{ 'Integration.Zoho.Start your account in ' + getLabel() | translate }}
    </h3>

    <div class="flex justify-content-center flex-wrap align-items-center mt-5">
      <p-button
        label="{{ 'Integration.Zoho.Go to ' + getLabel() | translate }}"
        icon="pi pi-sign-in" size="small"
        class="mr-2"
        type="button"
        (click)="login()"
      />
    </div>
  </div>
</div>
