import { Component, EventEmitter, HostListener, Output, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { emitData } from '../../models/emitData.interface';
import { PopupService } from '../../data-access/popup-autentication.service';

@Component({
	selector: 'app-login-form',
	templateUrl: './login-form.component.html',
	styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  zohoIntegration: string;

	private popupService = inject(PopupService);

  @HostListener('window:message', ['$event'])
  onMessage(event: MessageEvent) {
    const redirectUrl = event.data;
    const grantToken = this.getParameterValue(redirectUrl, 'code');
    if (this.popupService.popupWindow && !this.popupService.popupWindow.closed) {
      this.popupService.popupWindow.close();
    }
    this.emitAction.emit({
			action: 'login',
			values: grantToken,
      module: ''
		});
  }

  login(): any {
    let scope = environment.SCOPE_ZOHO_CRM;
    if (this.zohoIntegration == 'desk') {
      scope = environment.SCOPE_ZOHO_DESK;
    }

    let clientId = environment.CLIENT_ID_ZOHO_CRM;
    if (this.zohoIntegration == 'desk') {
      clientId = environment.CLIENT_ID_ZOHO_DESK;
    }

    const URL: string = environment.URL_API_AUTH_ZOHO + '?scope=' + scope + '&client_id=' + clientId + '&response_type=code&access_type=offline&redirect_uri=' + environment.URL_REDIRECT_ZOHO;
    this.popupService.redirectToZohoCRM(URL);
  }

	cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}

  getParameterValue(url: string, parameterName: string): string | null {
    const queryString = url.split('?')[1];
    const params = new URLSearchParams(queryString);
    return params.get(parameterName);
  }

  getLabel(): string {
    let label = 'Zoho Crm';
    if (this.zohoIntegration === 'desk') {
      label = 'Zoho Desk';
    }
    return label;
  }

  back(): void {
    this.emitAction.emit({
      action: 'close',
      values: '',
      module: ''
    });
  }
}
