import { inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
	name: 'filterSpecialCharactersOnMessage',
	standalone: true
})
export class FilterSpecialCharactersOnMessagePipe implements PipeTransform {
	private sanitizer = inject(DomSanitizer);

	public transform(message: string): SafeHtml {
		if (!message) return message;

		const urlRegex = /((https?:\/\/[^\s]+))/g;
		const urlTextFiltered = message.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');

		const result = urlTextFiltered
			.replace(/\*(\S(.*?\S)?)\*/g, '<b>$1</b>')
			.replace(/_(\S(.*?\S)?)_/g, '<i>$1</i>')
			.replace(/~(\S(.*?\S)?)~/g, '<s>$1</s>')
			.replace(/\n/g, '<br>');

		return this.sanitizer.bypassSecurityTrustHtml(result);
	}
}
