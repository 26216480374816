<div class="filter-container">
  <i
    tooltipPosition="left"
    (click)="togglePopup()"
    pTooltip="{{ 'Integration.Zoho.Filter' | translate }}"
    class="pi pi-filter mr-1"
  ></i>
  <div *ngIf="isPopupVisible" class="popup">
    <div class="flex flex-column gap-2">
      <label for="status">{{ 'Integration.Zoho.Status filter' | translate }}</label>
      <p-dropdown
        class="w-full"
        [options]="statusTypeOptions"
        [(ngModel)]="selectedStatus"
        optionLabel="label"
        optionValue="value"
      />
    </div>
    <div class="flex flex-column gap-2 mt-3">
      <label for="status">{{ 'Integration.Zoho.Channel filter' | translate }}</label>
      <p-dropdown
        class="w-full"
        [options]="channelTypeOptions"
        [(ngModel)]="selectedChannel"
        optionLabel="label"
        optionValue="value"
      />
    </div>

    <div class="flex flex-row gap-3 mt-3">

      <div class="col-md-6-custom">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="filter()">
          {{ 'Integration.Zoho.Filter' | translate }}
        </button>
      </div>

      <div class="col-md-6-custom">
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          (click)="cancel()">
          {{ 'Integration.Zoho.Restore' | translate }}
        </button>
      </div>

    </div>

  </div>
</div>
