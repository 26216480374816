<div class="field-phonenumber">
	<div class="select" [class.disabled]="isDisabled" (click)="viewOptions()">
		<div class="view-selection">
			<img
				class="image-icon"
				src="assets/images/flags/{{ extensionCodeSelected.iso }}.svg"
				alt="{{ extensionCodeSelected.name }}"
			/>
			<span class="extension-code">{{ extensionCodeSelected.code }}</span>

			<i class="material-icons-outlined">arrow_drop_down</i>
		</div>
	</div>
	<div
		*ngIf="showOptions"
		#dropdown
		class="options custom-scrollbar swing-in-top-fwd"
		(click)="handleOptionsClick($event)"
	>
		<div class="search-box position-relative">
			<input
				type="text"
				class="input-search d-block"
				placeholder="Search here..."
				[(ngModel)]="searchValue"
				(ngModelChange)="handleSearch()"
				[disabled]="isDisabled"
			/>
			<button type="submit" (click)="handleSearch()" [disabled]="isDisabled">
				<i class="material-icons-outlined">search</i>
			</button>
		</div>
		<div>
			<div
				*ngFor="let lang of langs"
				class="custom-option"
				[ngClass]="{ 'selected-option': lang.selected }"
				(click)="handleSelectedOption(lang)"
			>
				<img class="image-icon" src="assets/images/flags/{{ lang.iso }}.svg" alt="{{ lang.name }}" />
				<span class="extension-code">{{ lang.name }} ({{ lang.code }})</span>
			</div>
		</div>
	</div>
	<div [formGroup]="phoneForm" class="field">
		<p-inputMask formControlName="phoneNumber" [placeholder]="extensionCodeSelected.example" [mask]="phoneMask" />
	</div>
</div>
