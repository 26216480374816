import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ZohoCrmBackendService } from '../../../../data-access/zoho-crm-backend.service';

@Component({
  selector: 'app-activity-form',
  templateUrl: './activity-form.component.html',
  styleUrls: ['./activity-form.component.scss']
})
export class ActivityFormComponent implements OnDestroy, OnInit {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private subscriptions = new Subscription();
  public record?: any;
  public module?: string;
  public subModule?: string;
  sending = false;
  loaded = false;
  titleCode = '';

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  ngOnInit(): void {
    setTimeout(() => {
      this.titleCode = 'Integration.Zoho.Event data';
      if (this.subModule === 'tasks') this.titleCode = 'Integration.Zoho.Task data';
      if (this.subModule === 'calls') this.titleCode = 'Integration.Zoho.Call data';
      this.loaded = true;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  submit(data: emitData): void {
    this.sending = true;
    const createRecord$ = this._ZohoCrmBackendService
    .createActivity(data.values, this.module, this.subModule)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 204) {
          this.emitAction.emit({
              action: 'create',
              values: data.values,
              module: data.module
            });
        }
        this.sending = false;
        this.subscriptions.add(createRecord$);
    }, (error: HttpErrorResponse) => {
      this.sending = false;
      this.emitAction.emit({
        action: 'error',
        values: error,
        module: this.module
      });
    });
  }

  cancel(): void {
    this.emitAction.emit({
      action: 'cancel',
      values: '',
      module: this.module
    });
  }

}
