import { DOCUMENT } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Inject, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HubConnectionState } from '@microsoft/signalr';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { Howl } from 'howler';
import { catchError, forkJoin, of, Subscription } from 'rxjs';
import { ServiceInstanceService } from 'src/app/shared/data-access/service-instance.service';
import { MultiPickerFilterI } from 'src/app/shared/interfaces/multi-picker-filter.interface';
import { IServiceInstance } from 'src/app/shared/interfaces/service-instance.interface';
import { AuthState } from 'src/app/shared/states/auth.state';
import Swal from 'sweetalert2';
import { MultiPickerFilter } from '../../../shared/interfaces/multi-picker-filter.interface';
import { CustomerState } from '../../../shared/states/customer.state';
import { AgentConnectionState } from '../../data-access/enum/agent-connection-state.enum';
import { WhatsAppMessageStatus } from '../../data-access/enum/whatsapp-message.enum';
import { SetupChannelI } from '../../data-access/interface/configurator.interface';
import { IPager } from '../../data-access/interface/conversation.interface';
import { MessageType } from '../../data-access/interface/message.interface';
import { ServiceInstanceManagerI } from '../../data-access/interface/service-instance-manager.interface';
import { TransferMessage } from '../../data-access/interface/transfer-message.interface';
import { AgentConnectionStatus } from '../../data-access/models/agent-connection-status.model';
import { Chat } from '../../data-access/models/chat';
import { GetConversationQueryFilter } from '../../data-access/models/get-conversation-query-filter.model';
import { Message } from '../../data-access/models/message';
import { ConfiguratorService } from '../../data-access/services/configurator.service';
import { ConversationEventService } from '../../data-access/services/conversation-event.service';
import { MessagingHubService } from '../../data-access/services/messaging-hub.service';
import { TrafficService } from '../../data-access/services/traffic.service';
import {
	findChannelById,
	findConversationById,
	findExistConversationById,
	findObjectById,
	getIndexConversationById,
	getLastTimeStamp,
	removeConversationById,
	sortConversation
} from '../../utils/chat-method';

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy {
	@ViewChild('sucessfullTransfer') sucessfullTransfer: SwalComponent;
	@ViewChild('chatPanel') chatPanel: ElementRef;

	@Inject(DOCUMENT) private document: Document;

	private conversationEventService = inject(ConversationEventService);
	private serviceInstanceService = inject(ServiceInstanceService);
	private configuratorService = inject(ConfiguratorService);
	private messagingService = inject(MessagingHubService);
	private trafficService = inject(TrafficService);
	public translate = inject(TranslateService);
	private sanitizer = inject(DomSanitizer);
	private store = inject(Store);
	private router = inject(Router);
	private el = inject(ElementRef);

	public serviceInstancesData: IServiceInstance[] = [];
	public service;
	public message: string = '';
	public conversations: Chat[] = [];
	public selectedChat: Chat;
	public isSelectedChat = false;
	public isOpenModalIntegration: boolean = true;
	public isLoading: boolean = false;
	public isChangedConversation: boolean = false;
	public limit: number = 10;
	public offset: number = 0;
	public isFetchingConversations: boolean = false;
	public stateSidebar = 'none';
	public isAuthenticated: boolean;
	public currentConversationId: string = '';
	public sonido: Howl;
	public soundNotification: string = 'true';
	public serviceInstanceId: string = '';
	public serviceInstanceName: string = '';
	public setupName: string = '';
	public isFinish: boolean = false;

	public dataChannelSetup: SetupChannelI[] = [];
	public dataChannelSetupSelected: SetupChannelI = null;
	public setupIdArray: string[] = [];
	public arrayServiceInstancesManager: ServiceInstanceManagerI[] = [];
	public arrayServiceInstancesManagerCopy: ServiceInstanceManagerI[] = [];
	public serviceInstanceSelected: IServiceInstance = null;

	public scrollUpDistance = 2;
	public scrollDistance = 1;
	public throttle = 300;
	public numberConversations = 0;
	public offsetConversation = 0;
	public limitConversation = 10;
	public pageConversation = 0;
	// Diccionario para almacenar el timestamp del último mensaje recibido por conversación
	private lastMessageTimestampByConversation: { [conversationId: string]: number } = {};
	private minIntervalBetweenMessages = 5 * 1000; // Intervalo mínimo entre mensajes en milisegundos (5 segundos)

	private readonly threshold = 0.95;

	public conversationStates: { [conversationId: string]: IPager } = {};
	public hubConnectionState = HubConnectionState;
	public hubConnection: HubConnectionState;

	public filterOptions: MultiPickerFilterI[] = [];
	public pendingMessagesQueue: Message[] = [];
	public sendIdMessage: string = '';
	public useGeneratedImage = false;
	public optionsAgentsStatus: MultiPickerFilter[] = [];

	private networkStatusSubscription!: Subscription;

	ngOnInit(): void {
		this.isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);

		this.sonido = new Howl({
			src: ['../../../../assets/sound/notification_2.mp3'],
			volume: 0.9
		});
		this.soundNotification = localStorage.getItem('soundNotification')
			? localStorage.getItem('soundNotification')
			: 'true';

		this.generateImage();
		this.getServicesInstance();
		this.onReceiveMessage();
		this.watchHubConnectionStatus();
	}

	ngOnDestroy(): void {
		if (this.networkStatusSubscription) this.networkStatusSubscription.unsubscribe();
	}

	private isMediaMessage(messageType: MessageType): boolean {
		return [MessageType.Image, MessageType.Video, MessageType.Audio, MessageType.Sticker].includes(messageType);
	}

	private downloadAndSetMediaUrl(message: any): void {
		this.messagingService
			.downloadFile(message.message.id, this.serviceInstancesData[0].id, this.dataChannelSetupSelected.id)
			.subscribe((event) => {
				if (event.type === HttpEventType.Response) {
					message.message.url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.body!));
				}
			});
	}

	private generateImage(): void {
		const isUseGeneratedImage = localStorage.getItem('useGeneratedImage');
		if (isUseGeneratedImage) {
			this.useGeneratedImage = isUseGeneratedImage === 'true';
		}
	}

	getServicesInstance(): void {
		this.serviceInstanceService.getServiceInstances(0).subscribe({
			next: (servicesInstances) => {
				if (servicesInstances.length === 0) {
					this.router.navigate(['/chat/no-channels']);
					return;
				}

				const userId = localStorage.getItem('userId');
				this.serviceInstancesData = servicesInstances.filter((item) =>
					item.config.Agents.WhiteList.some((agent) => agent.Id === userId)
				);

				this.createFilterServiceInstance();

				// Recopilar todos los observables en un solo array
				const requests: any = servicesInstances.flatMap((servicesInstance) =>
					servicesInstance.config.Setups.map((setupInfo) =>
						this.configuratorService.getInformationConfig(setupInfo.SetupId).pipe(
							catchError((error) => of(null)) // Manejar errores devolviendo null en caso de error
						)
					)
				);

				this.getUnavailableAgents();

				// Ejecutar todas las solicitudes en paralelo usando forkJoin
				forkJoin(requests).subscribe({
					next: (responses: any[]) => {
						// Manejar la respuesta exitosa de las solicitudes
						const successfulResponses = responses.filter((response) => response !== null); // Filtrar respuestas exitosas
						this.dataChannelSetup.push(...successfulResponses); // Agregar respuestas exitosas al array de datos
						this.setupIdArray = this.dataChannelSetup.map((data) => data.businessNumber);

						this.isFinish = true;

						this.getConversations(true);
					},
					error: (error: any) => {
						// Manejar errores de las solicitudes
						console.error('Error fetching users:', error); // Registrar el error en la consola
					}
				});
			},
			error: (err) => console.error('Observable emitted an error: ' + err)
		});
	}

	createFilterServiceInstance(): void {
		const children = this.serviceInstancesData.map((instance) => ({
			value: instance.id,
			label: instance.name
		}));

		this.filterOptions.push({
			value: 'Filter by instance',
			label: 'Filter by instance',
			type: 'check',
			children: children
		});
	}

	private getUnavailableAgents(): void {
		const customerId = this.store.selectSnapshot(CustomerState.getCustomer)['customer_id'];
		let optionsAgentsStatusAux: MultiPickerFilter[] = [];
		this.optionsAgentsStatus = [];
		this.trafficService.getAgentsConnectionStatus(customerId).subscribe({
			next: (agentsWithStatusConnection: AgentConnectionStatus[]) => {
				this.fillOptionsAgentsStatusAux(agentsWithStatusConnection, optionsAgentsStatusAux);
				this.optionsAgentsStatus = [...optionsAgentsStatusAux];
			},
			error: (err) => console.error('Observable emitted an error: ' + err)
		});
	}

	private fillOptionsAgentsStatusAux(
		agentsWithStatusConnection: AgentConnectionStatus[],
		optionsAgentsStatusAux: MultiPickerFilter[]
	): void {
		this.serviceInstancesData[0].config.Agents.WhiteList.forEach((whiteListAgent: { Id: string; Name: any }) => {
			const matchingAgent = agentsWithStatusConnection.find((agent) => agent.agentId === whiteListAgent.Id);
			if (matchingAgent && matchingAgent.state !== AgentConnectionState.Closed) {
				optionsAgentsStatusAux.push({
					label: whiteListAgent.Name,
					value: whiteListAgent.Id,
					unavailable: matchingAgent.state !== AgentConnectionState.Active
				});
			}
		});
	}

	onReceiveMessage(): void {
		this.messagingService.onReceiveMessage((msg: Message) => {
			if (this.soundNotification === 'true') this.playNotificationSound(msg);

			if (this.isMediaMessage(msg.messageType)) this.downloadAndSetMediaUrl(msg);

			const indexServiceManager: number = findObjectById(this.arrayServiceInstancesManager, msg.to);
			const channelsByConfig = this.arrayServiceInstancesManager[indexServiceManager].config.channels;
			const indexChannel: number = findChannelById(channelsByConfig, msg.to);
			let conversationsByChannel: Chat[] =
				this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations;

			let chat = conversationsByChannel.find((c) => c.id == msg.conversationId);

			if (!findExistConversationById(this.arrayServiceInstancesManager, msg.conversationId)) {
				chat = this.getTransferredConversationData(msg, chat, indexServiceManager, indexChannel);
			} else {
				chat.addMessage(msg);
				chat.increaseUnSeenMsg();
				chat.setGroupedMessagesByDate();
			}

			const sortConversations = sortConversation(conversationsByChannel);
			conversationsByChannel = sortConversations;
		});
	}

	private getTransferredConversationData(
		msg: Message,
		chat: Chat,
		indexServiceManager: number,
		indexChannel: number
	): Chat {
		this.trafficService.getConversationById(msg.conversationId).subscribe((newConversation) => {
			chat = new Chat(
				msg.conversationId!,
				newConversation.customerContact!,
				'',
				newConversation.customerPhoneNumber!,
				msg.to!,
				msg,
				1,
				newConversation.channel
			);
			const newMessages = newConversation.messages.map((newMessage) => {
				return new Message(
					newMessage.id!,
					msg.conversationId!,
					newMessage.message!,
					newMessage.receivedOn!,
					newMessage.messageType!,
					newMessage.to!,
					newMessage.from!,
					newMessage.contact!,
					this.setupIdArray.includes(newMessage.from) ? WhatsAppMessageStatus.Delivered : null,
					''
				);
			});
			chat.setMessages(newMessages);
			this.arrayServiceInstancesManager[indexServiceManager].config.channels[indexChannel].conversations.push(chat);
			chat.setGroupedMessagesByDate();
		});
		return chat;
	}

	private watchHubConnectionStatus(): void {
		this.messagingService.connectionState$.subscribe((hubConnectionState: HubConnectionState) => {
			this.hubConnection = hubConnectionState;

			if (this.isOnline()) this.processPendingMessages();
		});
	}

	private processPendingMessages(): void {
		const messagesToSend = [...this.pendingMessagesQueue];

		messagesToSend.forEach((message, index) => {
			this.messagingService
				.sendMessage(message, message.setupId)
				.then(() => {
					setTimeout(() => {
						this.pendingMessagesQueue.splice(index, 1);
						this.sendIdMessage = message.id;
					});
				})
				.catch((error) => {
					message.status = WhatsAppMessageStatus.Error;
				});
		});
	}

	playNotificationSound(message: Message): void {
		const currentTime = Date.now();
		const conversationId = message.conversationId;
		const lastMessageTimestamp = this.lastMessageTimestampByConversation[conversationId] || 0;

		if (currentTime - lastMessageTimestamp > this.minIntervalBetweenMessages) {
			if (this.soundNotification === 'true') this.sonido.play();
			this.lastMessageTimestampByConversation[conversationId] = currentTime;
		}
	}

	getConversations(firstTime: boolean = false) {
		this.trafficService.conversation(this.offsetConversation, this.limitConversation).subscribe((_) => {
			this.numberConversations = _.pager.count;
			this.pageConversation += 1;
			this.arrayServiceInstancesManager = [];

			if (_.results?.length !== 0)
				_.results?.forEach((value, index, array) => {
					let messages: Message[] = [];

					value.messages?.forEach((msg, index, array) => {
						let message = new Message(
							msg.id!,
							value.id!,
							msg.message!,
							msg.receivedOn!,
							msg.messageType!,
							msg.to!,
							msg.from!,
							msg.contact!,
							this.setupIdArray.includes(msg.from) ? WhatsAppMessageStatus.Delivered : null,
							''
						);

						if (this.isMediaMessage(msg.messageType)) {
							this.dataChannelSetupSelected = this.searchSetup(value.bussinessPhoneNumber);
							this.downloadAndSetMediaUrl(msg);
						}

						messages.unshift(message);
					});

					let chat = new Chat(
						value.id!,
						value.customerContact,
						'',
						value.customerPhoneNumber!,
						value.bussinessPhoneNumber!,
						undefined,
						0,
						value.channel!,
						value.state
					);
					messages.forEach((value) => {
						chat.addMessage(value);
					});
					chat.setGroupedMessagesByDate();
					this.conversations.push(chat);
				});

			this.conversations = this.conversations.sort((conversacionA, conversacionB) => {
				const lastTimeStampA = getLastTimeStamp(conversacionA);
				const lastTimeStampB = getLastTimeStamp(conversacionB);

				return lastTimeStampB - lastTimeStampA;
			});

			this.isFetchingConversations = false;
			this.createObjectServiceInstanceManager();
			if (firstTime) this.checkIfShouldLoadMore();
		});
	}

	createObjectServiceInstanceManager(): void {
		this.serviceInstancesData.forEach((dataServiceInstance) => {
			const { id, name, config } = dataServiceInstance;
			const { Integrations, Bots, Extentions, Agents, Setups } = config;

			const serviceInstanceManager = {
				id,
				name,
				config: {
					integrations: Integrations,
					bots: Bots,
					extensiones: Extentions,
					agents: Agents,
					channels: []
				}
			};

			Setups.forEach((setupInfo) => {
				const filteredItemsDataChannel = this.dataChannelSetup.filter((item) => setupInfo.SetupId === item.id);

				if (filteredItemsDataChannel.length > 0) {
					const [channelInfo] = filteredItemsDataChannel;
					const conversations = this.conversations.filter((chat) => chat.businessPhone === channelInfo.businessNumber);

					serviceInstanceManager.config.channels.push({
						channelInfo,
						conversations
					});
				}
			});

			const exists = this.arrayServiceInstancesManager.some((element) => element.id === serviceInstanceManager.id);
			if (!exists) this.arrayServiceInstancesManager.push(serviceInstanceManager);
		});

		this.arrayServiceInstancesManagerCopy = this.arrayServiceInstancesManager;
	}

	private checkIfShouldLoadMore() {
		const element = this.chatPanel.nativeElement;
		const panelHeight = element.offsetHeight;
		const hasScroll = panelHeight > this.conversations.length * 92.1;
		const hasNextPage = this.pageConversation * this.limitConversation < this.numberConversations;
		if (hasScroll && hasNextPage) this.onScroll(null);
	}

	handleDataChanged(data: any): void {
		document.querySelector('.user-chat').classList.add('user-chat-show');
		document.querySelector('.chat-welcome-section').classList.add('d-none');
		document.querySelector('.user-chat').classList.remove('d-none');
		// Do something with the emitted data
		let chat = findConversationById(this.arrayServiceInstancesManager, data.conversationId);
		chat!.unSeenMsg = 0;
		this.conversationEventService.emitEvent(chat);

		this.selectedChat = chat!;
		this.currentConversationId = data.conversationId;
		this.isChangedConversation = !this.isChangedConversation;
		this.isSelectedChat = true;

		this.dataChannelSetupSelected = this.searchSetup(this.selectedChat.businessPhone);

		const indexServiceInstance: number = getIndexConversationById(
			this.arrayServiceInstancesManager,
			this.currentConversationId
		);
		this.serviceInstanceSelected = this.serviceInstancesData[indexServiceInstance];
		this.conversationEventService.emitEventTransfer(false);
	}

	handleMessageSend(message: Message): void {
		message.from = this.dataChannelSetupSelected.businessNumber;
		message.setupId = this.dataChannelSetupSelected.id;
		message.conversationId = this.selectedChat.id;
		message.channel = this.selectedChat.channel;

		this.selectedChat.setGroupedMessagesByDate();

		if (this.isOnline())
			this.messagingService
				.sendMessage(message, message.setupId)
				.then((_) => {
					this.sendIdMessage = message.id;
				})
				.catch((error) => {
					console.error('Failed to send message:', error);
					message.status = WhatsAppMessageStatus.Error;
				});
		else this.pendingMessagesQueue.push(message);
	}

	public handleFilter(event: any): void {
		if (event.search !== undefined) {
			this.conversationSearch(event);
			return;
		}

		if (event.filter) {
			const filterInstanceIds = event.filter['Filter by instance'];
			if (filterInstanceIds && filterInstanceIds.length > 0)
				this.arrayServiceInstancesManager = this.arrayServiceInstancesManagerCopy.filter((instance) =>
					filterInstanceIds.includes(instance.id)
				);
			else this.arrayServiceInstancesManager = [...this.arrayServiceInstancesManagerCopy];
		}
	}

	private conversationSearch(event: any): void {
		this.conversations = [];
		this.arrayServiceInstancesManager = [];

		if (event.search.length === 0) {
			this.getConversations();
		} else {
			let queryFilter = new GetConversationQueryFilter();
			queryFilter.searchTerm = event.search
			this.trafficService.getConversation(queryFilter).subscribe((result) => {
				this.conversations = [];
				result.results?.forEach((value) => {
					let messages: Message[] = [];
					value.messages?.forEach((msg) => {
						let message = new Message(
							msg.id!,
							value.id!,
							msg.message!,
							msg.receivedOn!,
							msg.messageType!,
							msg.to!,
							msg.from!,
							msg.contact!,
							this.setupIdArray.includes(msg.from) ? WhatsAppMessageStatus.Delivered : null,
							''
						);

						if (this.isMediaMessage(msg.messageType)) this.downloadAndSetMediaUrl(msg);

						messages.unshift(message);
					});

					let chat = new Chat(
						value.id!,
						value.customerContact,
						'',
						value.customerPhoneNumber!,
						value.bussinessPhoneNumber!,
						undefined,
						0,
						value.channel!,
						value.state
					);
					messages.forEach((value) => {
						chat.addMessage(value);
					});
					chat.setGroupedMessagesByDate();
					this.conversations.push(chat);
				});

				this.createObjectServiceInstanceManager();
			});
		}
	}

	onChatPanelClicked() {
		if (this.stateSidebar === 'none') {
			(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'block';
			this.stateSidebar = 'block';
		} else {
			(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
			this.stateSidebar = 'none';
		}
	}

	closeChatInfo(event: any) {
		(document.querySelector('.user-profile-sidebar') as HTMLElement).style.display = 'none';
		this.stateSidebar = 'none';
	}

	handleChatClosed(messageClose: string): void {
		const { id: chatId } = this.selectedChat;

		this.messagingService
			.closeConversation(chatId, messageClose)
			.then(() => {
				Swal.fire({
					icon: 'success',
					title: this.translate.instant('endChat.title2'),
					text: this.translate.instant('endChat.text2')
				});
			})
			.catch((error) => {});

		this.conversationEventService.emitEventTransfer(true);

		this.arrayServiceInstancesManager = removeConversationById(this.arrayServiceInstancesManager, chatId);
		this.isSelectedChat = false;

		const userProfileSidebar = document.querySelector('.user-profile-sidebar') as HTMLElement | null;
		if (userProfileSidebar) userProfileSidebar.style.display = 'none';

		const chatWelcomeSection = document.querySelector('.chat-welcome-section') as HTMLElement | null;
		if (chatWelcomeSection) chatWelcomeSection.classList.remove('d-none');

		this.stateSidebar = 'none';
	}

	initializeConversationState(conversationId: string): void {
		if (!this.conversationStates[conversationId]) {
			this.conversationStates[conversationId] = {
				offset: 0,
				limit: this.limit,
				count: 0,
				page: 1
			};
		}
	}

	handleMessageLoading(data: any) {
		let chat = findConversationById(this.arrayServiceInstancesManager, data.conversationId);

		this.initializeConversationState(data.conversationId);

		const conversationState = this.conversationStates[data.conversationId];
		conversationState.offset += conversationState.limit;
		conversationState.page += 1;

		if (conversationState.count === 0) this.getMessages(data.conversationId, conversationState);
		else if (conversationState.count > chat.messages.length) this.getMessages(data.conversationId, conversationState);
	}

	getMessages(conversationId: string, conversationState: IPager): void {
		this.trafficService.message(conversationState.offset, conversationState.limit, conversationId).subscribe((resp) => {
			let messages: Message[] = [];
			conversationState.count = resp.pager.count;
			resp.results?.forEach((msg, index, array) => {
				const message = new Message(
					msg.id!,
					conversationId,
					msg.message!,
					msg.receivedOn!,
					msg.messageType!,
					msg.to!,
					msg.from!,
					msg.contact!,
					WhatsAppMessageStatus.Sent,
					''
				);

				if (this.isMediaMessage(msg.messageType)) this.downloadAndSetMediaUrl(msg);

				messages.unshift(message);
			});

			if (this.selectedChat.messages.length + messages.length <= resp.pager?.count!) {
				messages.forEach((value) => {
					this.selectedChat.addMessage(value);
				});
				this.selectedChat.setGroupedMessagesByDate();
			}

			if (this.currentConversationId !== conversationId) this.currentConversationId = conversationId;
		});
	}

	getNameContact(conversation: Chat): string {
		const contactName = conversation.messages.filter((message) => this.setupIdArray.includes(message.to));
		return contactName.length !== 0 ? contactName[0].contact : '';
	}

	handleTransfer(object: TransferMessage): void {
		const body = {
			to: object.idUser,
			adapter: object.adapter,
			initialData: object.body
		};

		this.trafficService.transferConversation(this.selectedChat.id, body).subscribe({
			next: (_) => {
				this.arrayServiceInstancesManager = removeConversationById(
					this.arrayServiceInstancesManager,
					this.selectedChat.id
				);
        
				this.isSelectedChat = false;
				this.sucessfullTransfer.fire();
				this.conversationEventService.emitEventTransfer(true);

        const chatWelcomeSection = document.querySelector('.chat-welcome-section') as HTMLElement | null;
        if (chatWelcomeSection) chatWelcomeSection.classList.remove('d-none');
			},
			error: (err) => console.error('Observable emitted an error: ', err)
		});
	}

	searchSetup(businessNumber: string): any | null {
		const setupData = this.dataChannelSetup.find((setup) => setup.businessNumber === businessNumber);
		return setupData ? setupData : null;
	}

	onScroll(event: any) {
		this.offsetConversation += this.limitConversation;
		if (this.pageConversation * this.limitConversation < this.numberConversations) this.getConversations();
	}

	refreshPage(): void {
		window.location.reload();
	}

	public isOnline(): boolean {
		return this.hubConnection === HubConnectionState.Connected;
	}
}
