<div *ngIf="isFinish" class="d-lg-flex">
	<!-- start chat-leftsidebar -->
	<div class="chat-leftsidebar me-lg-1 ms-lg-0">
		<div class="tab-content">
			<!-- Start chats tab-pane -->
			<div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab">
				<!-- Start chats content -->
				<div class="chat-container">
					<h4 class="mb-0">{{ 'Recent chats' | translate }}</h4>

					<div *ngIf="hubConnection === hubConnectionState.Reconnecting" class="warning-alert">
						<span class="material-symbols-outlined icon"> wifi_off </span>
						<span>{{ 'Connection lost. Trying to reconnect.' | translate }}</span>
					</div>

					<div *ngIf="hubConnection === hubConnectionState.Disconnected" class="danger-alert">
						<span class="material-symbols-outlined icon"> signal_wifi_bad </span>
						<div class="text">
							<span>{{ 'connection_lost' | translate }}</span>
							<span class="refresh-link" (click)="refreshPage()">{{ 'refresh_page' | translate }}</span>
						</div>
					</div>

					<app-bar-search [options]="filterOptions" (emitValue)="handleFilter($event)"></app-bar-search>

					<!-- Start chat-message-list -->
					<div
						#chatPanel
						infinite-scroll
						[infiniteScrollContainer]="chat - message - list"
						[infiniteScrollUpDistance]="scrollUpDistance"
						[infiniteScrollDistance]="scrollDistance"
						[infiniteScrollThrottle]="throttle"
						[scrollWindow]="false"
						(scrolled)="onScroll($event)"
						class="chat-message-list"
					>
						<ng-container *ngFor="let item of arrayServiceInstancesManager">
							<app-chat-conversation
								[serviceInstanceManager]="item"
								[useGeneratedImage]="useGeneratedImage"
								(dataChanged)="handleDataChanged($event)"
							></app-chat-conversation>
						</ng-container>
					</div>
					<!-- End chat-message-list -->
				</div>
				<!-- Start chats content -->
			</div>
			<!-- End chats tab-pane -->
		</div>
	</div>

	<div class="w-100">
		<app-chat-welcome></app-chat-welcome>
		<!-- Start User chat -->
		<div class="user-chat w-100 overflow-hidden d-none" id="chat-room">
			<div class="w-100 d-lg-flex">
				<app-chat-content
					*ngIf="isSelectedChat"
					class="w-100 d-flex"
					[fullName]="selectedChat.customerContact"
					[messages]="selectedChat.messages"
					[phoneNumber]="selectedChat.clientPhone"
					[businnessPhoneNumber]="dataChannelSetupSelected.businessNumber"
					[nameChannel]="dataChannelSetupSelected.name"
					[serviceInstanceData]="serviceInstancesData[0]"
					[optionsAgentsStatus]="optionsAgentsStatus"
					[groupedMessagesByDate]="selectedChat.groupedMessagesByDate"
					[setupId]="dataChannelSetupSelected.id"
					[deliveredMessageId]="sendIdMessage"
					[useGeneratedImage]="useGeneratedImage"
					(chatClosed)="handleChatClosed($event)"
					(openPanel)="onChatPanelClicked($event)"
					(messageSended)="handleMessageSend($event)"
					(loadingMessage)="handleMessageLoading($event)"
					(transferMessage)="handleTransfer($event)"
				></app-chat-content>

				<!-- Chat Info Sidebar -->
				<app-integration-panel
					[serviceInstance]="serviceInstanceSelected"
					(closePanel)="closeChatInfo($event)"
				></app-integration-panel>
			</div>
		</div>
	</div>
</div>

<div *ngIf="!isFinish" class="spinner-container">
	<div class="spinner-border"></div>
	<span class="">{{ 'Loading' | translate }}</span>
</div>

<swal
	#sucessfullTransfer
	[toast]="true"
	position="top-end"
	[showConfirmButton]="false"
	[timer]="5000"
	[timerProgressBar]="true"
	title="{{ 'Transfer successful' | translate }}"
	icon="success"
>
</swal>
