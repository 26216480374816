import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';

import { SimplebarAngularModule } from 'simplebar-angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { TimeToChangeInterceptor } from '../data-access/interceptors/TimeToChange.interceptor';
import { AccountFormComponent } from '../ui/account-form/account-form.component';
import { ContactFormComponent } from '../ui/contact-form/contact-form.component';
import { SearchFieldTTCComponent } from '../ui/contact-form/search-field/search-field.component';
import { LoginFormComponent } from '../ui/login-form/login-form.component';
import { TicketFormComponent } from '../ui/ticket-form/ticket-form.component';
import { TicketListItemComponent } from '../ui/ticket-list/ticket-list-item/ticket-list-item.component';
import { TicketListComponent } from '../ui/ticket-list/ticket-list.component';
import { TimeToChangeComponent } from './time-to-change.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { WelcomeComponent } from '../ui/welcome/welcome.component';
import { LoadingComponent } from '../ui/loading/loading.component';

const primeNGModules = [AccordionModule, ButtonModule, DropdownModule, InputTextareaModule];

@NgModule({
	declarations: [
		TimeToChangeComponent,
		LoginFormComponent,
		ContactFormComponent,
		SearchFieldTTCComponent,
		TicketFormComponent,
		TicketListComponent,
		TicketListItemComponent,
		AccountFormComponent,
    WelcomeComponent,
    LoadingComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
    TooltipModule,
		SweetAlert2Module.forRoot(),
		ReactiveFormsModule,
		FormsModule,
		SimplebarAngularModule,
		NgScrollbarModule,
		SharedModule,
		...primeNGModules
	],

	exports: [TimeToChangeComponent],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TimeToChangeInterceptor,
			multi: true
		}
	]
})
export class TimeToChangeModule {}
