
<div class="card mt-5" >
  <ul id="fullNames" class="list-group list-group-flush">
    <li (click)="showLeads()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-user mr-1"></i>
      {{ 'Integration.Zoho.Leads' | translate }}
    </li>
    <li (click)="showContacts()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-user mr-1"></i>
      {{ 'Integration.Zoho.Contacts' | translate }}
    </li>
    <li (click)="createLead()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-user-plus mr-1"></i>
      {{ 'Integration.Zoho.Create leads' | translate }}
    </li>
    <li (click)="createContact()" class="list-group-item list-group-item-action cursor-pointer" >
      <i class="pi pi-user-plus mr-1"></i>
      {{ 'Integration.Zoho.Create contact' | translate }}
    </li>
  </ul>
</div>
