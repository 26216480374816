import { Component } from '@angular/core';

@Component({
	selector: 'app-text-format-menu',
	templateUrl: './text-format-menu.component.html',
	styleUrls: ['./text-format-menu.component.scss']
})
export class TextFormatMenuComponent {
	public textarea: HTMLTextAreaElement | null = null;
	public isVisible = false;
	public menuPosition = { bottom: '0px' };

	private BOLD_STYLE = 'bold';
	private ITALIC_STYLE = 'italic';
	private STRIKE_THROUGH_STYLE = 'strikethrough';

	public showFormatMenu(): void {
		if (this.textarea) {
			this.menuPosition = { bottom: `${this.getFormatMenuPosition()}px` };
			this.isVisible = true;
		}
	}

	public hideFormatMenu(): void {
		this.isVisible = false;
	}

	private getFormatMenuPosition(): number {
		const separationDistance = 5;
		const rect = this.textarea?.getBoundingClientRect();
		const windowHeight = window.innerHeight;
		const distanceFromTop = rect.top;
		const distanceToBottom = windowHeight - distanceFromTop + separationDistance;
		return distanceToBottom;
	}

	public applyFormat(format: string): void {
		if (this.textarea) {
			const start = this.textarea.selectionStart;
			const end = this.textarea.selectionEnd;
			let selectedText = this.textarea.value.substring(start, end);

			switch (format) {
				case this.BOLD_STYLE:
					selectedText = `*${selectedText}*`;
					break;
				case this.ITALIC_STYLE:
					selectedText = `_${selectedText}_`;
					break;
				case this.STRIKE_THROUGH_STYLE:
					selectedText = `~${selectedText}~`;
					break;
			}

			this.textarea.setRangeText(selectedText);
			const event = new Event('input', { bubbles: true });
			this.textarea.dispatchEvent(event);
      this.textarea.selectionStart = this.textarea.selectionEnd
			this.isVisible = false;
      this.textarea.focus();
		}
	}
}
