import { ConversationState } from "../enum/conversation-type.enum";
import { HandlerType } from "../enum/hadler-type.enum";

export class GetConversationQueryFilter {
	constructor(
		public offset: number = 0,
		public limit: number = 10,
		public searchTerm: string = null,
		public state: ConversationState = ConversationState.Active,
		public handler: HandlerType = HandlerType.Agent
	) {}
}
