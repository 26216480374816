import { Component, EventEmitter, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ZohoCrmBackendService } from '../../../../data-access/zoho-crm-backend.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnDestroy, OnInit {

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
	public translate = inject(TranslateService);
  private subscriptions = new Subscription();
  public record?: any;
  public module?: string;
  loading = false;
  hasError = false;
  activities = [];
  updateCache?: boolean;

  ngOnInit(): void {
    let recordId = '-1';
    if (this.record && this.record.id) {
      recordId = this.record.id;
    }
    this.getActivities(recordId, this.updateCache);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.confirmationService.close();
  }

  getActivities(recordId: string, update: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoCrmBackendService
    .getActivity(recordId, this.module, update)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.activities = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
    }, () => {
        this.loading = false;
        this.hasError = true;
    });
  }

  getIcon(type: string): string {
    if (type === 'tasks') return "pi-list-check";
    if (type === 'calls') return "pi-phone";
    return "pi-calendar-clock";
  }

  createTask(): void {
    this.emitAction.emit({
      action: 'create-tasks',
      values: '',
      module: ''
    });
  }

  createEvent(): void {
    this.emitAction.emit({
      action: 'create-events',
      values: '',
      module: ''
    });
  }

  createCall(): void {
    this.emitAction.emit({
      action: 'create-calls',
      values: '',
      module: ''
    });
  }

  confirm(type: any, id: string) {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.Are you sure of eliminating the activity?'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.deleteActivity(type, id);
          return;
        },
        key: 'activityDialogDeleteConfirm'
    });
  }

  deleteActivity(type: any, id: string): void {
    this.loading = true;
    const deleteDeals$ = this._ZohoCrmBackendService
    .deleteActivity(type, id)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 204) {
          this.messageService.showMessage('If you have satisfactorily eliminated the activity', 'success', false);
        }
        this.getActivities(this.record.id, true);
        this.subscriptions.add(deleteDeals$);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
      });
  }
}
