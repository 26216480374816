<div
	*ngIf="openPanelIntegration"
	class="integration-panel-sidebar"
	[ngClass]="{ 'hidden-panel': openPanel, active: integrationSelected || integrationZoho }"
>
	<div class="expansition" (click)="showPanel()">
		<i class="material-icons-outlined">{{ openPanel ? 'chevron_left' : 'chevron_right' }}</i>
	</div>

	<div *ngIf="hasSelectedIntegration">
		<div #childComponents></div>
		<!-- Dinamic components below -->
	</div>

	<app-select-integration-module
		[integrations]="integrations"
		[ocultIntegrationSidebar]="openPanel"
		(selectIntegration)="handleSelectModule($event)"
	></app-select-integration-module>
</div>
