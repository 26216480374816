import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { emitData } from '../../../models/emitData.interface';
import { MessageService } from '../../../utils/messages.service';
import { IntegrationConfigResponse, LayoutResponse } from '../../../models/integration-config-response.model';
import { IntegrationConfigZohoCrm } from '../../../models/integration-config-zoho-crm';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-integration-config',
	templateUrl: './integration-config.component.html',
	styleUrls: ['./integration-config.component.scss']
})
export class IntegrationConfigComponent implements OnInit {
	@Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  @ViewChild(ConfirmPopup) confirmPopup!: ConfirmPopup;

  private fb = inject(FormBuilder);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
	public translate = inject(TranslateService);
  public zohoType: string;
  public integrationConfigResponse: IntegrationConfigResponse;
  private layoutResponse: LayoutResponse ;
  form: FormGroup;
  sending = false ;
  formValid = true;

  ngOnInit(): void {
    this.layoutResponse = this.integrationConfigResponse.layouts ;
    this.createForm();
    this.showMessage();
  }

  getValues(): any {
    return Object.keys(this.layoutResponse);
  }

  createForm() {
    this.form = this.fb.group({});
    Object.keys(this.layoutResponse).forEach(key => {
      if (key) {
        if (this.layoutResponse[key]) {
          const group = this.fb.group({});
          this.layoutResponse[key].forEach(option => {
            group.addControl(option.name, this.fb.control(option.selected,[]));
          });
          this.form.addControl(key, group);
        }
      }
    });
    this.form.addControl('showOnlyRequiredFields', this.fb.control(
      this.integrationConfigResponse.showOnlyRequiredFields,
      []
    ));
  }

  createSave(): IntegrationConfigZohoCrm {
    const integrationConfigZohoCrm = new IntegrationConfigZohoCrm();
    integrationConfigZohoCrm.modules = this.integrationConfigResponse.modules;
    integrationConfigZohoCrm.showOnlyRequiredFields = this.integrationConfigResponse.showOnlyRequiredFields;
    integrationConfigZohoCrm.layouts = this.getlayouts() ;
    return integrationConfigZohoCrm;
  }

  getlayouts(): any[] {
    const result: any[] = [];
    this.formValid = true;
    let isSelected = false;
    Object.keys(this.form.value).forEach(module => {
      if (module !== 'showOnlyRequiredFields') {
        isSelected = false;
        if (this.form.value[module]) {
          Object.keys(this.form.value[module]).forEach(layoutName => {
              if (this.form.value[module][layoutName]) {
                isSelected = true;
                result.push({
                    module: module,
                    layoutName: layoutName
                });
              }
          });
        }
        if (!isSelected) {
          this.formValid = false;
        }
      }
    });
    return result;
  }

  onSubmit() {
    const payload = this.createSave();
    if (!this.formValid) {
      this.messageService.showMessage('All modules must have a selected layout','warning', true);
    } else {
      this.sending = true;
      this.emitAction.emit({
        action: 'save-init-config',
        values: payload,
        module: ''
      });
    }
  }

  back(): void {
    this.emitAction.emit({
      action: 'init',
      values: '',
      module: ''
    });
  }

  updateSelection(groupName: string, selectedName: string) {
    Object.keys(this.form.value[groupName]).forEach(layoutName => {
      this.updateControlValue(groupName, layoutName, false);
    });
    this.updateControlValue(groupName, selectedName, true);
  }

  updateControlValue(groupName: string, selectedName: string, newValue: any) {
    const group = this.form.get(groupName) as FormGroup;
    if (group) {
      const control = group.get(selectedName) as FormControl;
      if (control) {
        control.setValue(newValue);
      }
    }
  }

  updateShowOnlyRequiredFields($event) {
    this.integrationConfigResponse.showOnlyRequiredFields = $event.target.checked ;
  }

	cancel() {
		this.emitAction.emit({
			action: 'cancel',
			values: null,
      module: ''
		});
	}

  logout(): void {
		this.emitAction.emit({
      action: 'logout',
      values: '',
      module: ''
    });
	}

  getButtonTitle(): string {
    if (this.checkIfIsBeginOrSync()) {
     // this.messageService.showMessage('Press the start button to set up initial settings and sync with Zoho','info', true);
      return 'Begin'
    } else {
      return 'Sync';
    }
  }

  checkIfIsBeginOrSync(): boolean {
    let layoutsAsString = localStorage.getItem('leads-metadata-crm');
    if (this.zohoType === 'desk') {
       layoutsAsString = localStorage.getItem('tickets-metadata-desk');
    }
    return (layoutsAsString === null) ;
  }

  showMessage(): void {
    if (this.checkIfIsBeginOrSync()) {
      this.messageService.showMessage('Press the start button to set up initial settings and sync with Zoho','info', true);
    }
  }

  accept() {
    this.confirmPopup.accept();
  }

  reject() {
      this.confirmPopup.reject();
  }

  confirm() {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.This action will log you out of your Zoho account in Spellty. You won’t lose any data and can log back in with the same or a different account.'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.logout();
        },
        key: 'positionDialog'
    });
  }

  showBack(): boolean {

    console.error('this.zohoType: ', this.zohoType);
    if (this.zohoType === 'desk') {
      return !localStorage.getItem('tickets-metadata-desk');
    }
    if (this.zohoType === 'crm') {
      return !localStorage.getItem('leads-metadata-crm');
    }
    return false;
  }
}
