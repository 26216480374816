<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-1">
      <i
        tooltipPosition="left"
        (click)="back()"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
      ></i>
    </div>
    <div class="col-7">
      {{ 'Integration.Zoho.Details ticket' | translate }}
    </div>
    <div class="col-1" >
      <i
        tooltipPosition="left"
        (click)="update()"
        pTooltip="{{ 'Integration.Zoho.Update' | translate }}"
        class="pi pi-refresh mr-1"
      ></i>
    </div>
    <div class="col-3">
      <button
        tooltipPosition="left"
        [disabled]="ticket.status === 'Closed' || executing"
        pTooltip="{{ 'Integration.Zoho.Close ticket' | translate }}"
        type="button"
        class="btn btn-danger btn-sm"
        (click)="closeTickets()"
      >
        {{ 'Integration.Zoho.Close' | translate }}
      </button>
    </div>
  </div>
</h6>

<p-tabView *ngIf="!loading">
  <p-tabPanel header="{{ 'Integration.Zoho.Details' | translate }}">
    <table class="table-tickets-details">
      <tr>
        <td class="row-details tlabel">Ticket number</td>
        <td class="tvalue">#{{ ticket.ticketNumber }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Created</td>
        <td class="tvalue">{{ ticket.createdTimeFormated }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Departament</td>
        <td class="tvalue">
          <div *ngIf="ticket.department">
            <span>{{ ticket.department.name }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="row-details tlabel">Contact</td>
        <td class="tvalue">
          <div *ngIf="ticket.contact">
            <span>{{ ticket.contact.firstName }} {{ ticket.contact.lastName }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td class="row-details tlabel">Phone</td>
        <td class="tvalue">{{ ticket.phone }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Status</td>
        <td class="tvalue">
          <p-dropdown
            [disabled] = "executing"
            class="w-full"
            [options]="statusTypeOptions"
            [(ngModel)]="selectStatus"
            optionLabel="label"
            optionValue="value"
            (onChange)="changeStatus($event)"
          />
        </td>
      </tr>
      <tr>
        <td class="row-details tlabel">Assignee</td>
        <td class="tvalue">
          <p-dropdown
            [disabled] = "executing"
            [(ngModel)]="selectOwnerId"
            [options]="filteredSuggestions"
            optionLabel="label"
            optionValue="value"
            [filter]="true"
            filterBy="label"
            [showClear]="true"
            placeholder="Search agent"
            [loading]="loadingAgent"
            (onChange)="changeOwner($event)"
          ></p-dropdown>
        </td>
      </tr>
      <tr>
        <td class="row-details tlabel">Due date</td>
        <td class="tvalue">{{ ticket.dueDateFormated }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Priority</td>
        <td class="tvalue">{{ ticket.priority }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Channel</td>
        <td class="tvalue">{{ ticket.channel }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Language</td>
        <td class="tvalue">{{ ticket.language }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Category</td>
        <td class="tvalue">{{ ticket.category }}</td>
      </tr>
      <tr>
        <td class="row-details tlabel">Subcategory</td>
        <td class="tvalue">{{ ticket.subCategory }}</td>
      </tr>

    </table>
  </p-tabPanel>
  <p-tabPanel header="{{ 'Integration.Zoho.Comments' | translate }}">
      <app-tickets-comments
        [ticketId]="ticket.id"
      ></app-tickets-comments>
  </p-tabPanel>
</p-tabView>

<div *ngIf="loading" class="spinner-border" role="status">
  <span class="visually-hidden"></span>
</div>
