 <app-formly
    [recordData] = ""
    [module] = "'contacts'"
    [useLayout] = false
    [titleCode] = ""
    (emitAction)="submit($event)"
    [sending] = "sending"
    (cancelAction)="cancel()"
    [zohoType]="'desk'"
></app-formly>
