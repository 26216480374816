import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ZohoCrmBackendService } from '../../../../data-access/zoho-crm-backend.service';
import { MessageService } from 'src/app/integrations/zoho/utils/messages.service';
import { emitData } from 'src/app/integrations/zoho/models/emitData.interface';
import { ConfirmPopup } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrls: ['./deals-list.component.scss']
})
export class DealsListComponent implements OnDestroy, OnInit {

  private _ZohoCrmBackendService = inject(ZohoCrmBackendService);
  private messageService = inject(MessageService);
  private confirmationService = inject(ConfirmationService);
	public translate = inject(TranslateService);

  @Output() emitAction: EventEmitter<emitData> = new EventEmitter<emitData>();

  @ViewChild(ConfirmPopup) confirmPopup!: ConfirmPopup;

  private subscriptions = new Subscription();
  public record?: any;
  public module?: string;
  loading = false;
  deals = [];
  updateCache?: boolean;

  ngOnInit(): void {
    let recordId = '-1';
    if (this.record && this.record.id) {
      recordId = this.record.id;
    }
    this.get(recordId, this.updateCache);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  get(recordId: string, updateCache: boolean): void {
    this.loading = true;
    const createRecord$ = this._ZohoCrmBackendService
    .getDeals(recordId, updateCache)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 200) {
          if (resp.body) {
            this.deals = resp.body;
          }
        }
        this.loading = false;
        this.subscriptions.add(createRecord$);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
      });
  }

  deleteDeal(id: string): void {
    this.loading = true;
    const deleteDeals$ = this._ZohoCrmBackendService
    .deleteDeals(this.record.id, id)
    .subscribe((resp: HttpResponse<any>) => {
        if (resp.status == 204) {
          this.messageService.showMessage('If you have satisfactorily eliminated the opportunity', 'success', false);
        }
        this.get(this.record.id, true);
        this.subscriptions.add(deleteDeals$);
      }, (error: HttpErrorResponse) => {
        this.loading = false;
        this.emitAction.emit({
          action: 'error',
          values: error,
          module: ''
        });
      });
  }

  confirm(id: string) {
    this.confirmationService.confirm({
        message: this.translate.instant('Integration.Zoho.Are you sure of eliminating the opportunity?'),
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptIcon:"none",
        rejectIcon:"none",
        rejectButtonStyleClass:"p-button-text",
        accept: () => {
          this.deleteDeal(id);
        },
        key: 'dealDialogDeleteConfirm'
    });
  }
}
