
<h6  class="mb-1 p-3" >
  <div class="row">
    <div class="col-1">
      <i
        (click)="back()"
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.Back' | translate }}"
        class="pi pi-arrow-left mr-1"
      ></i>
    </div>
    <div class="col-10">
      {{ 'Integration.Zoho.Contact search' | translate }}
    </div>
  </div>
</h6>

<div class="card mt-5" style="text-align: center;" >

  <p> {{ 'Integration.Zoho.No contacts found' | translate }} </p>

  <ul id="fullNames" class="list-group list-group-flush">

    <li style="cursor: pointer;" (click)="newContact()" class="list-group-item list-group-item-action" >
      <i
        tooltipPosition="left"
        pTooltip="{{ 'Integration.Zoho.Create contact' | translate }}"
        class="pi pi-plus-circle mr-1"
      ></i>
      {{ 'Integration.Zoho.Create contact' | translate }}
    </li>
  </ul>
</div>
