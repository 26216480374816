<div class="mb-1 pl-1 pr-1 pt-3 pb-3">
  <p-accordion  [(activeIndex)]="activeIndex">
    <p-accordionTab [header]="headerDetailsTitle | translate">
      <table *ngIf="loaded" class="table-tickets-details">
        <tr *ngFor="let defaultDetail of defaultDetails">
          <td class="row-details tlabel">{{ mapDefaultTitles(defaultDetail.title) }}</td>
          <td class="tvalue">{{ defaultDetail.value }}</td>
        </tr>
      </table>

      <table *ngIf="seeMore" class="table-tickets-details">
        <tr *ngFor="let moreDetail of moreDetails">
          <td class="row-details tlabel" style="width: 130px;">{{ mapMoreTitles(moreDetail.title) }}</td>
          <td class="tvalue">{{ moreDetail.value }}</td>
        </tr>
      </table>

      <p-button label="{{ 'Integration.Zoho.' + (seeMore ? 'See less' : 'See more') | translate }}" (onClick)="seeMore = !seeMore" [link]="true" />


    </p-accordionTab>
    <p-accordionTab >
      <ng-template pTemplate="header">
          <span>{{ 'Integration.Zoho.Notes' | translate }}</span>
          <div
            class="icon-container"
            [class.visible]="activeIndex === 1"
            style="text-align: end;width: 200px;"
          >
            <div style="float: right; width: 60px;" (click)="$event.stopPropagation()">
              <i (click)="onAddNote()" class="pi pi-plus" style="margin-left: 10px; cursor: pointer;"></i>
              <i (click)="onRefreshNotes()" class="pi pi-refresh" style="margin-left: 10px; cursor: pointer;"></i>
            </div>
          </div>
      </ng-template>
      <app-record-notes
      #recordNotes
      [module]="module"
      [record]="record"
      (emitAction)="emitOthersAction($event)"
    ></app-record-notes>
    </p-accordionTab>
  </p-accordion>

</div>
