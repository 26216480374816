
<div *ngIf="show">
  <div class="mb-3">
    <label for="exampleFormControlTextarea1" class="form-label">
      {{ 'Integration.Zoho.new note' | translate }}
    </label>
    <textarea
      pInputTextarea
      [(ngModel)]="noteContent"
      class="form-control"
      rows="3"
      [disabled]="savingNote"
    >
    </textarea>
  </div>

  <div class="flex justify-content-center flex-wrap align-items-center">
    <p-button
      label="{{ 'Integration.Zoho.add' | translate }}"
      icon="pi pi-plus"
      size="small"
      class="mr-2"
      type="submit"
      [disabled]="savingNote"
      (onClick)="addNote()"
    />
  </div>
</div>

<div *ngIf="loadingNotes" class="spinner-border mt-5" role="status">
  <span class="visually-hidden"></span>
</div>

<div *ngIf="!loadingNotes && notes && notes.length > 0" class="list-group list-group-flush mt-2 notes">
  <div *ngFor="let note of notes">
    <div class="icon-container" >
      <p-card [style]="{ paddingLeft: '15px', paddingRight: '15px' }">
        <div class="flex justify-content-between gap-5">
          <div class="flex flex-column gap-1">
              <span class="text-md">{{  note.content }}</span>
              <span *ngIf="note.owner" class="text-secondary text-sm"> {{ note.owner }}</span>
              <span class="text-secondary text-sm">{{ note.createAt }}</span>
          </div>
          <span class="w-2rem h-2rem border-circle inline-flex justify-content-center align-items-center text-center" >
            <i
              (click)="confirm(note.id)"
              tooltipPosition="left"
              pTooltip="{{ 'Integration.Zoho.Remove' | translate }}"
              class="pi pi-trash mr-2 trans"
            ></i>
          </span>
        </div>
      </p-card>
    </div>
  </div>
</div>

<span *ngIf="!loadingNotes && (!notes || notes.length == 0)" class="mt-2">
  <div *ngIf="!show" class="card mt-5" style="text-align: center;" >
    <p> {{ 'Integration.Zoho.No notes found' | translate }} </p>
    <ul id="fullNames" class="list-group list-group-flush">
      <li style="cursor: pointer;" (click)="showForm()" class="list-group-item list-group-item-action" >
        <i
          tooltipPosition="left"
          pTooltip="{{ 'Integration.Zoho.new notet' | translate }}"
          class="pi pi-plus-circle mr-1"
        ></i>
        {{ 'Integration.Zoho.new note' | translate }}
      </li>
    </ul>
  </div>
</span>

<p-confirmDialog
  key="noteDialogDeleteConfirm"
  [position]="'right'"
  rejectButtonStyleClass="p-button-outlined" >
  <ng-template pTemplate="message" let-message>
    <div class="custom-dialog-content">
      {{ message.message }}
    </div>
  </ng-template>
</p-confirmDialog>
