import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CustomerState } from 'src/app/shared/states/customer.state';
import { environment } from 'src/environments/environment';
import { ConversationState } from '../enum/conversation-type.enum';
import { HandlerType } from '../enum/hadler-type.enum';
import { IConversation, IConversationPage } from '../interface/conversation.interface';
import { IMessagePage } from '../interface/message.interface';
import { AgentConnectionStatus } from '../models/agent-connection-status.model';
import { GetConversationQueryFilter } from '../models/get-conversation-query-filter.model';

@Injectable({
	providedIn: 'root'
})
export class TrafficService {
	private baseUrl = environment.URL_API_TRAFFIC;
	private http = inject(HttpClient);
	private store = inject(Store);

	conversation(
		offset: number = 0,
		limit: number = 10,
		state: ConversationState = ConversationState.Active,
		handler: HandlerType = HandlerType.Agent
	): Observable<IConversationPage> {
		let customer = this.store.selectSnapshot(CustomerState.getCustomer);
		let url_ =
			this.baseUrl +
			`/api/Conversation?offset=${offset}&limit=${limit}&State=${state}&handler=${handler}&AssignedTo=${customer.sub}`;

		return this.http.get<any>(url_).pipe(catchError(this.handleError<any>('getMyData', [])));
	}

	public getConversation(queryFilter: GetConversationQueryFilter): Observable<IConversationPage> {
		let customer = this.store.selectSnapshot(CustomerState.getCustomer);
		let params = new HttpParams()
			.set('offset', queryFilter.offset.toString())
			.set('limit', queryFilter.limit.toString())
			.set('State', queryFilter.state)
			.set('handler', queryFilter.handler)
			.set('AssignedTo', customer.sub)
			.set('searchTerm', queryFilter.searchTerm);

		// Make the HTTP GET request
		return this.http.get<IConversationPage>(`${this.baseUrl}/api/Conversation`, { params }).pipe(
			tap((response) => {
				// Process the response if needed
			}),
			catchError(
				this.handleError<IConversationPage>('getMyData', {
					/* Default value for IConversationPage */
				})
			)
		);
	}

	message(offset: number = 0, limit: number = 10, conversationId: string): Observable<IMessagePage> {
		let url_ = this.baseUrl + `/api/Message?offset=${offset}&limit=${limit}&conversationId=${conversationId}`;

		return this.http.get<IMessagePage>(`${url_}`).pipe(
			tap((_) => {}),
			catchError(this.handleError<any>('getMyData', []))
		);
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			console.error(error);
			return of(result as T);
		};
	}

	transferConversationToAgent(id: string, body: any): Observable<any> {
		return this.http.post(`${this.baseUrl}/api/Conversation/${id}/transfer/agent`, body);
	}

	transferConversation(id: string, body: any): Observable<any> {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});

		const req = new HttpRequest('POST', `${this.baseUrl}/api/Conversation/${id}/transfer`, body, {
			reportProgress: true,
			headers: headers
		});

		return this.http.request(req);
	}

	public getConversationById(id: string): Observable<IConversation> {
		const url = `${this.baseUrl}/api/Conversation/${id}`;
		return this.http.get<IConversation>(url);
	}

	public getAgentsConnectionStatus(customerId: string): Observable<AgentConnectionStatus[]> {
		const url = `${this.baseUrl}/api/Connections?customerId=${customerId}`;
		return this.http.get<AgentConnectionStatus[]>(url);
	}
}
