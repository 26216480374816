<p-dropdown
  [options]="accounts"
  (onChange)="emitSelectAcount($event)"
  [(ngModel)]="accountName"
  optionLabel="name"
  optionValue="id_account"
  [filter]="true"
  [showClear]="true"
  [placeholder]="placeholder"
  [loading]="loading"
  (onFilter)="filter($event)"
></p-dropdown>
